import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    TextField
} from "@mui/material";
import FieldViewService from "../../services/FieldViewService";
import FieldViewResponse from "../../interfaces/FieldView/FieldViewResponse";
import CloseIcon from '@mui/icons-material/Close';
import {useListContext} from "react-admin";

interface Props {
    setOpenAdvancedFilter: any;
    openAdvancedFilter: boolean;
}

const FieldService = new FieldViewService();

const AdvancedCookFilterDialog = (props: Props) => {

    const {setFilters, filterValues} = useListContext();

    const [fields, setFields] = useState<FieldViewResponse[]>([]);

    const [filter, setFilter] = useState<{key: string; value: string}[]>([{key: '', value: ''}]);

    const resetFilter = () => {
        setFilters({}, [], false);
        setFilter([{key: '', value: ''}]);
    };

    useEffect(() => {
        if (filterValues?.additionalDetailFilters) {
            setFilter(JSON.parse(filterValues?.additionalDetailFilters));
        }
    }, [filterValues?.additionalDetailFilters]);

    useEffect(() => {
        FieldService.getAllDetailParameters(100, 0).then((res) => {
            setFields(res?.data);
        })
    }, []);

    return(
        <Dialog open={props?.openAdvancedFilter} onClose={() => {props?.setOpenAdvancedFilter(false)}}>
            <DialogTitle>
                Advanced filter Dialog
            </DialogTitle>
            <DialogContent>
                {/*add the filter here*/}
                {filter?.map((eachFilter, index) => {
                    return(
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} key={eachFilter?.key}>
                            <TextField
                                select
                                label="Select Label"
                                fullWidth
                                value={eachFilter?.key}
                                sx={{width: 200}}
                                onChange={(e) => {
                                    const val = e.target.value;
                                    const updatedFilters = [...filter];
                                    updatedFilters[index] = { key: val, value: '' };
                                    setFilter(updatedFilters);
                                }}
                            >
                                {/* Add menu items dynamically based on labels */}
                                {fields.map((eachField) => (
                                    <MenuItem key={eachField?.id} value={eachField?.label}>
                                        {eachField?.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Enter Value"
                                fullWidth
                                value={eachFilter?.value}
                                sx={{ml: 2, width: 200}}
                                onChange={(e) => {
                                    const val = e.target.value;
                                    const updatedFilters = [...filter];
                                    updatedFilters[index] = { key: eachFilter?.key, value: val };
                                    setFilter(updatedFilters);
                                }}
                            />
                            <IconButton
                                disabled={filter.length === 1}
                                sx={{ml: 2}}
                                onClick={() => {
                                    filter.splice(index, 1);
                                    setFilter([...filter]);
                                }}
                                color={'error'}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )
                })}
                <Button variant={'outlined'} sx={{mt: 2}} onClick={() => {
                    setFilter((prevState) => [...prevState, {key: '', value: ''}])
                }}>
                    {'Add Filter'}
                </Button>
                <Button color={'error'} variant={'outlined'} sx={{mt: 2, ml: 2}} onClick={resetFilter}>
                    {'Clear Filter'}
                </Button>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} color={'error'} onClick={() => {props?.setOpenAdvancedFilter(false)}}>
                    {'Close'}
                </Button>
                <Button variant={'contained'} color={'primary'} onClick={() => {
                    setFilters({additionalDetailFilters: JSON.stringify(filter)}, [], false);
                    props?.setOpenAdvancedFilter(false);
                }}>
                    {'Filter'}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default AdvancedCookFilterDialog;
