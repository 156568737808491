import { Box, Typography, Tooltip, Button, Dialog, DialogContent, DialogActions, DialogTitle, FormControl, TextareaAutosize, Select, InputLabel, MenuItem } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, GridValueGetterParams } from "@mui/x-data-grid";
import DisplayNoDataFound from "../Common/DisplayNoDataFound";
import {
  CookService,
  GetCookWalletResponse,
  GetCookWalletTransactionsResponse,
} from "../../services/CookServices";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import moment from "moment";
import TransactionButton from "../Common/TransactionButton";
import VendorPayoutService from "../../services/VendorPayoutService";
import { Link } from "react-admin";
import { getReasonCategory } from "../Common/constants/constant";
import ReasonSubreasonModuleEnum from "../../enums/ReasonSubreasonModuleEnum";
import { ActionTypeEnum } from "../../enums/ActionTypeEnum";

const cookServices = new CookService();
const vendorPayoutServices = new VendorPayoutService();

const RevokePayout = (referenceId: { referenceId: number }) => {
  const RevokePayoutDialog = (props: { open: boolean, onClose: () => void }) => {
    const [revokeReason, setRevokeReason] = useState<string>("");
    const [reasons,setReasons]=useState<any>([]);
    const [other,setOther]=useState<string>("")
    const handleConfirm = () => {
      vendorPayoutServices.revokeVendorPayout(Number(referenceId?.referenceId), {
        comments: revokeReason==="Other"?other:revokeReason,
      }).then((res) => {
        if (res) {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Revoke Payout Successful",
            type: CustomSnackbarTypes.SUCCESS,
          });
        }
      }).catch((err) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: err?.response?.data?.error,
          type: CustomSnackbarTypes.ERROR,
        })
      }).finally(() => {
        props?.onClose();
      })
    }

    useEffect(()=>{
      getReasonCategory(ReasonSubreasonModuleEnum.REVERSE_PAYOUT, ActionTypeEnum.PENALTY_REVOKE).then((data) => {
        setReasons(data);
        
    }).catch((err) => {
        console.log(err);
    })
    },[])
    return (
      <Dialog open={props?.open} onClose={props?.onClose}>
        <DialogTitle>Please confirm the revoke of the payout.</DialogTitle>
        <DialogContent>
          <FormControl style={{ width: 500 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Reason</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={revokeReason}
                label="Reason"
                onChange={(e)=>setRevokeReason(e.target.value)}
              >
                {
                  reasons&&Array.isArray(reasons)&&reasons.length>0&&reasons.map((reasonObj:any)=>(
                    <MenuItem key={reasonObj?.category} value={reasonObj?.category}>{reasonObj?.category}</MenuItem>
                  ))
                }
              </Select>
              {
                revokeReason === "Other" && (
                  <TextareaAutosize
                    value={other}
                    minRows={5}
                    onChange={(event) => {
                      setOther(event.target.value);
                    }}
                  />
                )
              }
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color={"error"}
            onClick={props?.onClose}
            size={"large"}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color={"success"}
            onClick={() => {
              handleConfirm();
            }}
            size={"large"}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const [openRevokePayoutDialog, setOpenRevokePayoutDialog] = useState<boolean>(false);
  return (
    <>
      <TransactionButton name={"Revoke Payout"} color="error" onClickHandler={() => setOpenRevokePayoutDialog(true)} />
      <RevokePayoutDialog open={openRevokePayoutDialog} onClose={()=> setOpenRevokePayoutDialog(false)}/>
    </>
  )
}
const transactionColumns = [
  { field: "id", headerName: "ID", width: 90 },
  { field: "cookWalletId", headerName: "Wallet ID", width: 100 },
  { field: "amount", headerName: "Amount", width: 100 },
  {
    field: "notes",
    headerName: "Notes",
    minWidth: 250,
    flex: 1,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <Box>
          <Tooltip title={params?.row?.notes ?? ""}>
            <Typography variant="body1">{params?.row?.notes ?? ""}</Typography>
          </Tooltip>
        </Box>
      );
    },
  },
  {
    field: "createdAt",
    headerName: "Created At",
    width: 300,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <Typography>
          {params?.row?.createdAt ? moment(params?.row?.createdAt).format("LLL") : "---"}
        </Typography>
      )
    }
  },
  {
    field: "referenceId", headerName: "Reference ID", width: 150,
    renderCell: (params: GridValueGetterParams) => {
      if (params?.row?.referenceModelName === "VENDOR_PAYOUT") {
        return (
          <Link to={`/VendorPayouts/${params?.row?.referenceId}/show`}>{params?.row?.referenceId}</Link>
        )
      }
      else  if (params?.row?.referenceModelName === "ONE_MEAL_SERVICE") {
        return (
          <Link to={`/OneMealService/${params?.row?.referenceId}/show`}>{params?.row?.referenceId}</Link>
        )
      }
      else  if (params?.row?.referenceModelName === "TRIAL") {
        return (
          <Link to={`/Trials/${params?.row?.referenceId}/show`}>{params?.row?.referenceId}</Link>
        )
      }
      else {
        return (
          <div>{params?.row?.referenceId}</div>
        )
      }
    }
  },
  {
    field: "vendorPayoutRevoke", headerName: "Revoke Payout", width: 250,
    renderCell: (params: GridValueGetterParams) => {
      if (params?.row?.referenceId !== null && params?.row?.referenceModelName === "VENDOR_PAYOUT" && params?.row?.fromRevoked !== true) {
        return <RevokePayout referenceId={params?.row?.referenceId} />
      }
      else {
        return (
          <div>REVOKE PAYOUT NOT AVAILABLE</div>
        )
      }
    }
  },
  {
    field: "noteEn", headerName: "Note English", width: 550,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <div>{params?.row?.vendorPayoutEvent?.noteLocalEn}</div>
      )
    }
  },
  {
    field: "noteHi", headerName: "Note Hindi", width: 550,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <div>{params?.row?.vendorPayoutEvent?.noteLocalHi}</div>
      )
    }
  },
  {
    field: "noteBn", headerName: "Note Bangla", width: 550,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <div>{params?.row?.vendorPayoutEvent?.noteLocalBn}</div>
      )
    }
  },
  {
    field: "vendorPayoutEventType", headerName: "Vendor Payout Event Type", width: 250,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <div>{params?.row?.vendorPayoutEvent?.type}</div>
      )
    }
  },
  {
    field: "vendorPayoutEventProduct", headerName: "Vendor Payout Event Product", width: 300,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <div>{params?.row?.vendorPayoutEvent?.product}</div>
      )
    }
  },
  {
    field: "vendorPayoutEvents", headerName: "Vendor Payout Event", width: 200,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <div>{params?.row?.vendorPayoutEvent?.event}</div>
      )
    }
  },
  {
    field: "vendorPayoutEventTags", headerName: "Vendor Payout Event Tags", width: 400,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <>
          {params?.row && params?.row?.vendorPayoutEvent && params?.row?.vendorPayoutEvent?.tags?.length !== 0 &&
            params?.row?.vendorPayoutEvent?.tags?.map((value: string, index: number) => {
              return (
                <Box display={'flex'} key={index}>
                  <div>{value}</div>
                  {index !== params?.row?.vendorPayoutEvent?.tags?.length - 1 && 
                    <div>,&nbsp;</div>
                  }
                </Box>
              )
            })}
        </>
      )
    }
  },
  {
    field: "vendorPayoutEventReasons", headerName: "Vendor Payout Reason", width: 600,
    renderCell: (params: GridValueGetterParams) => {
      return (
        <div>{params?.row?.vendorPayoutEvent?.reason}</div>
      )
    }
  },
];

const CookWalletTab = (props: { cookId: number }) => {
  const [cookWalletData, setCookWalletData] = useState<GetCookWalletResponse>();
  const [cookWalletTransactions, setCookWalletTransactions] =
    useState<GetCookWalletTransactionsResponse>();
  const [pageSize, setPageSize] = useState(10);
  const [loading,setLoading] = useState(false);

  const cookWalletRedeem = async () => {
    try {
      if (cookWalletData?.amount) {
        setLoading(true);
        const response = await cookServices.redeemCookWalletBalance(
          props.cookId,
          cookWalletData?.amount!
        );
        if (response.status === 200) {
          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: "Redeem Successful",
            type: CustomSnackbarTypes.SUCCESS,
          });
          setLoading(false)
          fetchCookWalletData();
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  const fetchCookTransactionData = useCallback(
    async (pageNo: number) => {
      const cooksWalletTransactionResponse =
        await cookServices.getCookWalletTransactions(
          props.cookId,
          pageSize,
          pageSize * pageNo,
          {order: "DESC", field: "createdAt"}
        );
      setCookWalletTransactions(cooksWalletTransactionResponse);
    },
    [props.cookId, pageSize]
  );
  const fetchCookWalletData = useCallback(async () => {
    const cooksWalletResponse = await cookServices.getCookWallet(props.cookId);
    setCookWalletData(cooksWalletResponse);
  }, [props.cookId]);
  useEffect(() => {
    fetchCookWalletData();
  }, [fetchCookWalletData]);
  useEffect(() => {
    fetchCookTransactionData(0);
  }, [fetchCookTransactionData]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          mb: "1rem",
        }}
      >
        Amount: {cookWalletData?.amount ?? "0"}
        {
          !loading?<Button
          sx={{ ml: "2rem" }}
          onClick={cookWalletRedeem}
          variant="contained"
          // disabled={!(parseFloat(cookWalletData?.amount ?? "") >= 200)}
        >
          Redeem
        </Button>:<Button disabled>Processing</Button>
        }
      </Box>
      <Box sx={{ height: "29rem", width: "100%" }}>
        {cookWalletTransactions?.data?.length !== 0 ? (
          <DataGrid
            disableColumnMenu
            density="compact"
            rows={cookWalletTransactions?.data ?? []}
            columns={transactionColumns}
            pageSize={pageSize}
            onPageSizeChange={(pageSize) => {
              setPageSize(pageSize);
            }}
            rowCount={cookWalletTransactions?.count ?? 0}
            paginationMode="server"
            onPageChange={(page) => {
              fetchCookTransactionData(page);
            }}
            rowsPerPageOptions={[5, 10, 25, 50]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        ) : (
          <DisplayNoDataFound text="No Data Found" />
        )}
      </Box>
    </Box>
  );
};

export default CookWalletTab;
