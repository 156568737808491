import { Button } from "@mui/material";
import {Dispatch, useEffect, useState} from "react";
import {
  Datagrid,
  DateField,
  List,
  RaRecord,
  ReferenceField,
  SelectInput,
  TextField,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { HouseReviewStatus } from "../../enums/HouseReviewStatus";
import ApproveHouseReview from "../../interfaces/HouseReview/ApproveHouseReview";
import HouseReviewService from "../../services/HouseReviewService";
import ApproveHouseReviewDialog from "../Common/ApproveHouseReviewDialog";
import ConfirmationDialog from "../Common/ConfirmationDialog";
import RejectHouseReviewDialog from "../Common/RejectHouseReviewDialog";
import CityService from "../../services/CityService";
import CityResponse from "../../interfaces/City/CityResponse";

const houseReviewService = new HouseReviewService();

interface InputProps {
  setRejectHouseDialog: Dispatch<React.SetStateAction<boolean>>;
  setSelectedHouseReview: Dispatch<React.SetStateAction<RaRecord | undefined>>;
  setApproveDialog: Dispatch<React.SetStateAction<boolean>>;
}

const CTAButtons = (props: InputProps) => {
  const record = useRecordContext();

  return (
    <div
      style={{
        display: "flex",
        gap: 10,
      }}
    >
      {record.status === HouseReviewStatus.PENDING && (
        <>
          <Button
            variant={"contained"}
            size="small"
            color="primary"
            onClick={() => {
              props.setApproveDialog(true);
              props.setSelectedHouseReview(record);
            }}
            disableElevation
          >
            Approve
          </Button>

          <Button
            variant={"contained"}
            size="small"
            color="error"
            onClick={() => {
              props.setRejectHouseDialog(true);
              props.setSelectedHouseReview(record);
            }}
            disableElevation
          >
            Reject
          </Button>
        </>
      )}
    </div>
  );
};

const cityService = new CityService();

const HouseReviewList = () => {
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [selectedHouseReview, setSelectedHouseReview] = useState<RaRecord>();
  const [rejectHouseDialog, setRejectHouseDialog] = useState(false);
  const [cities, setCities] = useState<CityResponse[]>([]);
  const refresh = useRefresh();

  const handleApproveHouse = (data: ApproveHouseReview) => {
    if (selectedHouseReview) {
      houseReviewService
        .approve(selectedHouseReview.id as number, {
          localityId: data.localityId,
          cityId: data.cityId,
          areaId: data.areaId,
        })
        .then(refresh);
    }
  }

    const filters = [
        <SelectInput
            label="City"
            source="cityId"
            alwaysOn
            emptyText="Clear Filter"
            choices={cities.map((eachCity) => {
                return {
                    id: eachCity?.id,
                    name: eachCity?.name,
                };
            })}
        />,
        <SelectInput
            label="Status"
            source="status"
            alwaysOn
            emptyText="Clear Filter"
            choices={Object.values(HouseReviewStatus).map((key, i) => {
                return {
                    id: key,
                    name: key,
                };
            })}
        />,
    ];

  useEffect(() => {
      cityService.fetchAllCities(100, 0, {}, {}).then((res) => {
          setCities(res?.data);
      })
  }, []);

  return (
    <div>
      <ApproveHouseReviewDialog
        open={showApproveDialog}
        setOpen={setShowApproveDialog}
        callback={(data) => {
          handleApproveHouse(data)
        }}
      />

      {rejectHouseDialog && (
        <RejectHouseReviewDialog
          data={selectedHouseReview as any}
          open={rejectHouseDialog}
          setOpen={setRejectHouseDialog}
        />
      )}

      <ConfirmationDialog />
      <List filters={filters} sort={{field: 'id', order: 'DESC'}}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="id" />
          <ReferenceField source="houseId" link="show" reference="Houses">
            <TextField source="id" />
          </ReferenceField>
          <TextField source="status" />
          <ReferenceField source="cityId" link="show" reference="Cities">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="areaId" link="show" reference="Areas">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="localityId"
            link="show"
            reference="Localities"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="otherSlc" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <CTAButtons
            setRejectHouseDialog={setRejectHouseDialog}
            setApproveDialog={setShowApproveDialog}
            setSelectedHouseReview={setSelectedHouseReview}
          />
        </Datagrid>
      </List>
    </div>
  );
};

export default HouseReviewList;
