import {
  ArrayField,
  BooleanField,
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  RaRecord,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import CustomReferenceField from "../Common/CustomReferenceField";
import moment from "moment";
import CheckInAndOutImages from "../Common/CheckInAndOutImages";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import { useAppSelector } from "../../store/hooks";
import {Card, Chip, Box, Typography, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Link as MUILink} from "@mui/material";
import { Link } from "react-router-dom";
import {UserType} from "../../enums/common";
import CallButton from "../Common/CallButton/CallButton";
import React, { useEffect, useState } from "react";
import { TrialsService } from "../../services/TrialServices";
import JsonViewer from "../Common/JsonViewer";
import ImagePreviewer from "../Common/ImagePreviewer";
import PermanentReplacementService from "../../services/PermanentReplacementService";



function trialOwnerOrAllocator(label: string, url: string, input: string) {
  return (
      <div>
          <label style={{color: "grey", fontSize: "12px"}}>{label}</label>
          <br/>
          <Link to={{pathname: url}}>{input}</Link>
      </div>
  )
}

const TrialTime = (props: {label?: string}) => {
const record =  useRecordContext()
return (
  <div>
  {moment(record?.checkOutTime).diff(moment(record?.checkInTime), "minutes") || null}
  </div>
)
}
const permanentReplacementService = new PermanentReplacementService();
const TrialsMetaShow = (props: any) => {
  const userDetails = useAppSelector((state) => state.userDetails.value);
  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const promotorFlag = (userDetails.adminUser.accessGroups as any)?.includes(UserGroupsEnum.PROMOTOR);
  const record = useRecordContext();
  const [customerRatings, setCustomerRatings] = useState<any>([{}]);
  const [prDetails, setPrDetails] = useState<any>();


  const getPRDetails = (prId: number) => {
    if(!prId) return;
    permanentReplacementService.getPrDetailsById(prId)
        .then((res) => {
          if(res) {
            setPrDetails(res);
          }
        })
  }
  useEffect(() => {
    const getCustomerRatings = async (model: string, id: string) => {
      const trialService = new TrialsService();
      const data = await trialService.getCustomerRatings(id, model);
      setCustomerRatings([data?.data]);
    }
    getCustomerRatings('TRIAL_V3', record?.id.toString());
    getPRDetails(record?.prId);
  }, [record?.id, record?.prId])
  return (
    <Show {...props} actions={promotorFlag ? <CreateButton /> : null} title=" ">
      <Box pl={'30px'}>
        {prDetails && prDetails?.prInitiationTags && Object?.entries(prDetails?.prInitiationTags)?.map(([reason, subReasons], index: number) => (
            <Box key={reason} display={'flex'} gap={'40px'} alignItems={'center'}>
              <p><b>PR Reason {index + 1}: </b>{reason}</p>
              {Array?.isArray(subReasons) && subReasons?.map((subReason: string, ind: number) => (
                  <p><b>PR Sub Reason {ind + 1}: </b>{subReason}</p>
              ))}
            </Box>
        ))}
        {prDetails && prDetails?.comments && (
            <Box>
              <p><b>PR Comments: </b>{prDetails?.comments}</p>
            </Box>
        )}
      </Box>
      <Box display={'flex'}>
        <Chip
            sx={{width: '100%', m: 2}}
            color={record?.isPartOfDiscovery ? 'success' : 'default'}
            label={record?.isPartOfDiscovery ? `Discovery Trail` : `Not a Discovery Trial`}
        />
      </Box>
      <div
        style={
          promotorFlag
            ? {}
            : { display: "grid", gridTemplateColumns: "25% 30% 45%" }
        }
      >
        <SimpleShowLayout>
          <NumberField emptyText="" source="id" />
          <TextField emptyText="" source="bundlePlan" />
          <TextField emptyText="" source="status" />
          {record?.razorpayOrder?.clientId && <FunctionField label="Source of booking" render={(record: RaRecord) => {
            if (record?.razorpayOrder?.clientId === 2) {
              return (
                <div>CENTAURUS</div>
              )
            }
            else if (record?.razorpayOrder?.clientId === 4) {
              return (
                <div>CUSTOMER APP</div>
              )
            }
            else if (record?.razorpayOrder?.clientId === 9) {
              return (
                <div>WEBSITE</div>
              )
            }
          }} />}
          <DateField showTime emptyText="" source="paymentStatusLastUpdatedAt" />
          <TextField emptyText="" label="Trial Payment Status" source="paymentStatus" />
          <TextField emptyText="" source="trialAmount" />
          <FunctionField
            label="Visit Time"
            render={(record: { date: any; time: any }) =>
              `${record?.date ? moment(record.date).format('DD/MM/YYYY') : 'DD/MM/YYYY hh:mm A'} ${record?.time || ''}`
            }
          />
          <FunctionField
            label="Pre Checkin At"
            render={(record: RaRecord) =>
              `${
                record?.preCheckedInAt
                  ? moment(record.preCheckedInAt).format('DD/MM/YYYY hh:mm A') + ` (${record.preCheckedInType})`
                  : 'Not Available'
              }`
            }
          />
          <FunctionField
            label="Check In"
            render={(record: RaRecord) =>
              `${record?.checkInTime ? moment(record.checkInTime).format('DD/MM/YYYY hh:mm A') : 'Not Available'}`
          }
          />
          <FunctionField
            label="Check Out"
            render={(record: RaRecord) =>
              `${record?.checkOutTime ? moment(record.checkOutTime).format('DD/MM/YYYY hh:mm A') : 'Not Available'}`
          }
          />
          <TextField label="Discount Amount" emptyText="" source="discount" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <ArrayField emptyText="" label="Meal Mapping" source="mealMapping">
            <Datagrid bulkActionButtons={false} style={{ minWidth: "250px" }}>
              <TextField emptyText="" source="slot" />
              <TextField
                emptyText=""
                source="time"
                style={{ width: "61px", display: "inlineBlock" }}
              />
              <TextField emptyText="" label="Buffer" source="bufferMinutes" />
            </Datagrid>
          </ArrayField>
          {trialOwnerOrAllocator(
            "User Bundle",
            `/UserBundles/${record.userBundleMappingId}/show`,
            record?.userBundleMappingId
          )}
          <TextField emptyText="" source={"allocationType"} />
          <TextField emptyText="" source={"trialCookCut"} />
          <TextField emptyText="" source={"sector"} />
        </SimpleShowLayout>
        <SimpleShowLayout>
          {record.userBundleMappingDetails && (
            <div>
              <p
                style={{
                  margin: "0px",
                  fontSize: "12px",
                  color: "grey",
                }}
              >
                Payment Status
              </p>
              <p
                style={{
                  margin: "0px",
                }}
              >
                {record.userBundleMappingDetails.status === "PENDING"
                  ? "Payment Pending"
                  : "Payment Done"}
              </p>
            </div>
          )}
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "5rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
              backgroundColor: "#ffeb3b",
              color: "#000",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <TextField emptyText="" source="editNotes" label={<span style={{color:"#525252"}}>CUSTOMER NOTES</span>}/>
            </SimpleShowLayout>
          </Card>
          <TextField emptyText="" source={"cancellationReason"} />
          <TextField emptyText="" source={"cancellationSubReason"} />
          <TextField emptyText="" source={"rescheduleReason"} />
          <TextField emptyText="" source={"rescheduleSubReason"} />
          <TextField emptyText="" source={"cookRejectionReason"} />
          <TextField emptyText="" source={"notConvertedReason"} />
          <TextField emptyText="" source={"notConvertedSubReason"} />
              {record?.house?.lat !== null && record?.house?.long !== null && (
            <div>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${record?.house?.lat},${record?.house?.long}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to google maps
              </a>
            </div>
          )}
        </SimpleShowLayout>
        <SimpleShowLayout>
          <NumberField emptyText="" source="totalPeople" />
          <TextField emptyText="" source="totalPrice" />
          <TextField emptyText="" source="slotName" />
          <TextField emptyText="" source="genderPreference" />
          <FunctionField
            emptyText=""
            label="Address"
            render={(record: {
              house: { address: any; cityName: any; localityName: any; areaName: any; };
            }) =>
                [record?.house?.address, record?.house?.localityName, record?.house?.areaName, record?.house?.cityName]?.filter((each) => each !== null).join(', ')
            }
          />
          <CustomReferenceField
            label="House"
            listName={"Houses"}
            source={"houseId"}
          />
          <TextField emptyText="" source="customerName" />
          <TextField emptyText="" source="customerRating" />
          <TextField emptyText="" source="customerFeedback" />
          <CustomReferenceField
            label="Area Id"
            listName={"Areas"}
            source={"areaId"}
          />
          <CustomReferenceField
            label="City Id"
            listName={"Cities"}
            source={"cityId"}
          />
          <CustomReferenceField
            label="Locality Id"
            listName={"Localities"}
            source={"localityId"}
          />
          <CustomReferenceField
            label="Cook"
            listName={"Cooks"}
            source={"cookId"}
          />
          <FunctionField
            emptyText=""
            label="Cook Name"
            render={(record: RaRecord) => {
                if (!record?.cook?.cookFullProfile?.firstName) return `Not Available`
                return `${record?.cook?.cookFullProfile?.firstName} ${record?.cook?.cookFullProfile?.lastName}`;
              }
            }
          />
          <CallButton
              heading={'Cook phone number'}
              toPhoneNumber={record?.cook?.mobile}
              userType={UserType.COOK}
          />
          <BooleanField emptyText="" source="cookAcknowledged" />
          <TextField emptyText="" source={"cookCut"} />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <CallButton
              heading={'Customer phone number'}
              toPhoneNumber={record?.customerPhoneNumber}
              userType={UserType.CUSTOMER}
          />
          {GroupPermissionFunction(groupsArray, [
            UserGroupsEnum.ADMIN,
            UserGroupsEnum.MANAGER,
          ]) ? (
            trialOwnerOrAllocator(
              "Trial Owner ID",
              `/AdminUsers/${record.trialOwner}/show`,
              record.trialOwner
            )
          ) : (
            <TextField emptyText="" source="trialOwner" />
          )}
          <TextField emptyText="" source="trialOwnerName" />
          <TextField emptyText="" source="trialOwnerEmail" />
          {GroupPermissionFunction(groupsArray, [
            UserGroupsEnum.ADMIN,
            UserGroupsEnum.MANAGER,
          ]) ? (
            trialOwnerOrAllocator(
              "Trial Allocator ID",
              `/AdminUsers/${record.trialAllocator}/show`,
              record.trialAllocator
            )
          ) : (
            <TextField emptyText="" source="trialAllocator" />
          )}
          <TextField emptyText="" source="trialAllocatorName" />
          <TextField emptyText="" source="trialAllocatorEmail" />
          <TextField emptyText="" source="adminNotes" />
          <div>
            <label style={{ color: "grey", fontSize: "12px" }}>Pr Id</label>
            <br />
            <Link to={`/PermanentReplacement/${record.prId}/show`}>
              {record.prId}
            </Link>
          </div>
          <div>
            <label style={{ color: "grey", fontSize: "12px" }}>
              Is Pr Trial
            </label>
            <br />
            <span>{record.prId ? "Yes" : "No"}</span>
          </div>
          <NumberField emptyText="" label="Cook Accepted" source="numberOfCookAccepted" />
          <NumberField
            emptyText=""
            label="Shortlisted Cook"
            source="numberOfCookAllocated"
          />
          <TextField emptyText="" label="Cancelled By" source="cancelledBy" />
          <TextField emptyText="" label="Cancelled By Email" source="cancelledByEmail" />
          <TextField emptyText="" source="deniedBy" />
          <TextField emptyText="" source="deniedByType" />
          <TrialTime label="Trial Time" />
          <FunctionField
            label="Created At"
            render={(record: RaRecord) =>
              `${record?.createdAt ? moment(record.createdAt).format('DD/MM/YYYY hh:mm A') : 'Not Available'}`
          }
          />
          <FunctionField
            label="Cancelled At"
            render={(record: RaRecord) =>
              record?.cancelledAt ? <div>{moment(record.cancelledAt).format('DD/MM/YYYY hh:mm A')}</div> : <div>Not Available</div>
            }
          />

        </SimpleShowLayout>
        <SimpleShowLayout>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "15rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
            }}
            elevation={0}
          >

            <SimpleShowLayout>
              <ArrayField label="Customer Preferences according to Cook" source="cxPreferences">
                <Datagrid bulkActionButtons={false}>
                  <TextField source="question"/>
                  <TextField source="answer"/>
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "12rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <ArrayField emptyText="" source="supportComments">
                <Datagrid bulkActionButtons={false}>
                  <TextField emptyText="" source="comment" />
                  <TextField emptyText="" source="commenterEmail" />
                  <DateField emptyText="" source="timestamp" showTime />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "12rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <ArrayField emptyText="" source="customerComments">
                <Datagrid bulkActionButtons={false}>
                  <TextField emptyText="" source="comment" />
                  <TextField emptyText="" source="commenterEmail" />
                  <DateField emptyText="" source="timestamp" showTime />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "12rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <ArrayField emptyText="" label={'Allocation/Tracker Comments'} source="allocationComments">
                <Datagrid bulkActionButtons={false}>
                  <TextField emptyText="" source="comment" />
                  <TextField emptyText="" source="commenterEmail" />
                  <DateField emptyText="" source="timestamp" showTime />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
          <Card
            style={{
              overflow: "auto",
              borderRadius: "4px",
              height: "12rem",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 1px 3px",
            }}
            elevation={0}
          >
            <SimpleShowLayout>
              <ArrayField emptyText="" source="chefFeedback">
                <Datagrid bulkActionButtons={false}>
                  <TextField emptyText="" source="comment" />
                  <TextField emptyText="" source="commenterEmail" />
                  <DateField emptyText="" source="timestamp" showTime />
                </Datagrid>
              </ArrayField>
            </SimpleShowLayout>
          </Card>
        </SimpleShowLayout>
      </div>
      <SimpleShowLayout>
        <Box>
          <Typography fontFamily={'inherit'} fontWeight={500} my={'16px'}>Previous PR Trials</Typography>
          {record?.oldTrials && record?.oldTrials?.length > 0 && (
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Trial ID</TableCell>
                      <TableCell align="center">Trial Date</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Not Converted Reason</TableCell>
                      <TableCell align="center">Cancellation Reason</TableCell>
                      <TableCell align="center">Cancellation Sub-Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {record?.oldTrials?.map((trial: any, index: number) => (
                        <TableRow key={trial?.id}>
                          <TableCell align="center">
                            <MUILink href={`/#/Trials/${trial?.id}/show`}>
                              {trial?.id}
                            </MUILink>
                          </TableCell>
                          <TableCell align="center">{trial?.date}</TableCell>
                          <TableCell align="center">{trial?.status}</TableCell>
                          <TableCell align="center">{trial?.notConvertedReason}</TableCell>
                          <TableCell align="center">{trial?.cancellationReason}</TableCell>
                          <TableCell align="center">{trial?.cancellationSubReason}</TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
          )}
        </Box>
      </SimpleShowLayout>
      <SimpleShowLayout>
        <Box>
            <Typography fontFamily={'inherit'} fontWeight={500} my={'16px'}>Customer Ratings</Typography>
            {customerRatings && customerRatings?.length !== 0 &&
              <TableContainer component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Overall Ratings</TableCell>
                      <TableCell align="center">Behaviour Rating</TableCell>
                      <TableCell align="center">Cleanliness Rating</TableCell>
                      <TableCell align="center">Food Quality Rating</TableCell>
                      <TableCell align="center">Comments</TableCell>
                      <TableCell align="center">Photos</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {customerRatings?.map((row: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell align="center" sx={{ fontWeight: 500 }}>
                          <Chip label={row?.overallRating}/>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            {row?.behaviourRating && <Chip sx={{mb: '4px'}} label={row?.behaviourRating} />}
                            {row?.ratingTags && row?.ratingTags["Behavior related"]?.length !== 0 &&
                            row?.ratingTags["Behavior related"]?.map((val: any, ind: number) => {
                              return (
                                <Box key={ind} display={'flex'} flexDirection={'column'} mb={'4px'}>
                                  <Chip label={val} />
                                </Box>
                              )
                            })}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            {row?.cleaninessRating && <Chip sx={{mb: '4px'}} label={row?.cleaninessRating} />}
                            {row?.ratingTags && row?.ratingTags["Hygiene Related"]?.length !== 0 &&
                            row?.ratingTags["Hygiene Related"]?.map((val: any, ind: number) => {
                              return (
                                <Box key={ind} display={'flex'} flexDirection={'column'} mb={'4px'}>
                                  <Chip label={val} />
                                </Box>
                              )
                            })}
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          <Box>
                            {row?.foodQualityRating && <Chip sx={{mb: '4px'}} label={row?.foodQualityRating} />}
                            {row?.ratingTags && row?.ratingTags["Food related"]?.length !== 0 &&
                            row?.ratingTags["Food related"]?.map((val: any, ind: number) => {
                              return (
                                <Box key={ind} display={'flex'} flexDirection={'column'} mb={'4px'}>
                                  <Chip label={val} />
                                </Box>
                              )
                            })}
                          </Box>
                        </TableCell>
                        <TableCell align="center">{row?.comments}</TableCell>
                        <TableCell align="center">
                          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'5px'}>
                            {row?.photos && row?.photos?.length !== 0 &&
                            row?.photos?.map((photo: any, ind: number) => (
                              <Box sx={{cursor: 'pointer'}}>
                                <ImagePreviewer key={ind} value={photo} width={"70px"} height={"70px"}/>
                              </Box>
                            ))}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
        </Box>
      </SimpleShowLayout>
      {/* <CustomerRatingsImages service="TRIALS" /> */}
      <CheckInAndOutImages />
      <JsonViewer data={record} />
    </Show>
  );
};

export default TrialsMetaShow;

