import React, {useCallback, useEffect, useState} from 'react';
import {CookService} from "../../services/CookServices";
import {
    Button,
    Box,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Autocomplete
} from "@mui/material";
import CircularLoader from "../Common/CircularLoader";
import EmptyListPlaceholder from "../Common/EmptyListPlaceholder";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";

interface InputProps {
    cookId: number
}

const cookService = new CookService();

const CookRecommendedDishes = (props: InputProps) => {

    let [subData, setSubData] = useState<any>(null);
    let [isLoading, setIsLoading] = useState<boolean>();
    let [open, setOpen] = useState<boolean>(false);

    const [category, setCategory] = useState<{id: number; name: string}[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<number>();

    const [dishes, setDishes] = useState<{id: number; name: string}[]>([]);
    const [selectedDish, setSelectedDish] = useState<number>();

    const refreshData = useCallback(() => {
        if(!props?.cookId) return;
        setIsLoading(true)
        cookService.getAllRecommendedDish(props?.cookId)
            .then((data) => {
                setSubData(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            })
    }, [props?.cookId])

    const handleSubmit = () => {
        if(!selectedCategory){
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Select a Category",
                type: CustomSnackbarTypes.WARNING,
            });
            return;
        }
        if(!selectedDish){
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Select a Dish",
                type: CustomSnackbarTypes.WARNING,
            });
            return;
        }
        cookService.addRecommendedDish(props?.cookId, selectedDish).then(() => {
            refreshData();
            setOpen(false);
            setSelectedDish(undefined);
            setSelectedCategory(undefined);
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Added Successfully",
                type: CustomSnackbarTypes.SUCCESS,
            });
        });
    };

    const handleDelete = (id: number) => {
        EventBus.emitEvent(
            EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG,
            {
                title: "Are you sure?",
                message: "Please confirm to remove this dish",
                successLabel: "Confirm",
                failureLabel: "Cancel",
                onClose: (confirmed: boolean) => {
                    if (confirmed) {
                        cookService.removeRecommendedDish(props?.cookId, id).then(() => {
                            refreshData();
                            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                message: "Removed Successfully",
                                type: CustomSnackbarTypes.SUCCESS,
                            });
                        });
                    }
                },
            }
        );

    }

    useEffect(() => {
        refreshData();
    }, [refreshData])

    useEffect(() => {
        if(!selectedCategory) return;
        cookService.getAllCuisineCategoryDishes(selectedCategory).then((res: {id: number; name: string}[]) => {
            setDishes(res);
            setSelectedDish(undefined);
        });
    }, [selectedCategory]);

    useEffect(() => {
        cookService.getAllCuisineCategories().then((res: {id: number; name: string}[]) => {
            setCategory(res);
        });
    }, []);

    return (
        <div>
            <Box display={'flex'} flexDirection={'row-reverse'} my={2}>
                <Button size={'small'} variant={'contained'} onClick={() => {setOpen(true)}}>
                    {'Add Dish'}
                </Button>
            </Box>
            {
                isLoading ?
                    <CircularLoader/>
                    :
                    <div>
                        {
                            subData &&
                            Array.isArray(subData) &&
                            (
                                subData?.length > 0 ?
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Idx</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Category</TableCell>
                                                    <TableCell>Meal Type</TableCell>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell/>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {subData?.map((row: any, index: number) => {
                                                    return (
                                                        <TableRow
                                                            key={row.id}
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                        >
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{row?.name}</TableCell>
                                                            <TableCell>{row?.category?.name}</TableCell>
                                                            <TableCell>{row?.mealType}</TableCell>
                                                            <TableCell>{row?.type}</TableCell>
                                                            <TableCell>
                                                                <Button onClick={() => { handleDelete(row?.id) }} variant={'contained'} color={'error'} size={'small'}>
                                                                    Remove
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    :
                                    <EmptyListPlaceholder
                                        message='No Recommended Dishes'
                                    />
                            )
                        }
                    </div>
            }
            {open && (
                <Dialog open={open} onClose={() => {setOpen(false)}} maxWidth={'md'}>
                    <DialogTitle>
                        Add Dish
                    </DialogTitle>
                    <DialogContent sx={{minWidth: 350}}>
                        <Box display={'flex'} flexDirection={'column'}>
                            <TextField
                                id="select-cuisine-select"
                                select
                                label="Select Cuisine"
                                fullWidth
                                value={selectedCategory}
                                onChange={(e: any) => {
                                    setSelectedCategory(e.target.value);
                                }}
                                SelectProps={{
                                    native: false,
                                }}
                            >
                                {category.map((option) => (
                                    <MenuItem key={option?.id} value={option?.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {selectedCategory && (
                                <Autocomplete
                                    id="select-dish-select"
                                    options={dishes?.map((each: {id: number; name: string}) => {
                                        return {label: each?.name, value: each?.id}
                                    })}
                                    onChange={(_, data: any) => {
                                        setSelectedDish(data?.value)
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Dish" />}
                                />
                            )}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button size={'small'} variant={'outlined'} onClick={() => {setOpen(false)}}>
                            close
                        </Button>
                        <Button size={'small'} variant={'contained'} color={'error'} onClick={()=> {handleSubmit()}}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default CookRecommendedDishes;
