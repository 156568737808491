import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
} from "@mui/material";
import {MgCookResponse} from "../../interfaces/Cook/MgCookResponse";
import React, {useState} from "react";
import {CreateMgCookDialog} from "../Common/CreateMgCookDialog";
import {CookService} from "../../services/CookServices";
import EventBus from "../../EventBus";
import {EventBusEventsEnum} from "../../enums/EventBusEventsEnum";
import {CustomSnackbarTypes} from "../Common/CustomSnackBar";

interface PropsInterface {
  data: MgCookResponse[];
  cookId: number;
  callBack: () => void;
}

const cookService = new CookService();

const CookSalaryList = (props: PropsInterface) => {
  const [editMgCook, setEditMgCook] = useState<boolean>(false);
  const [addComment, setAddComment] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [editData, setEditData] = useState<MgCookResponse>();

  const handleAddComment = () => {
    if(!editData) return;
    cookService.createMgCookSalaryComment(Number(editData?.id), {
      comment: comment,
    }).then(() => {
      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
        message: 'Added Successfully',
        type: CustomSnackbarTypes.SUCCESS
      });
      setAddComment(false);
      props.callBack();
    })
  }
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{'Sl.No'}</TableCell>
            <TableCell>{'Start Date'}</TableCell>
            <TableCell>{'End Date'}</TableCell>
            <TableCell>{'Salary'}</TableCell>
            <TableCell>{'Comments'}</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row: MgCookResponse, index: number) => {
            return (
              <TableRow key={row?.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row?.startDate || '---'}</TableCell>
                <TableCell>{row?.endDate || '---'}</TableCell>
                <TableCell>{row?.salary || '---'}</TableCell>
                <TableCell sx={{maxWidth: 400}}>
                  <Table size={'small'}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{'Sl.No'}</TableCell>
                        <TableCell>{'Comment'}</TableCell>
                        <TableCell>{'Commented By'}</TableCell>
                        <TableCell>{'Commented By Type'}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row?.comments?.map((eachComment, commentIndex: number) => {
                        return (
                            <TableRow key={eachComment?.comment}>
                              <TableCell>{commentIndex + 1}</TableCell>
                              <TableCell>{eachComment?.comment || '---'}</TableCell>
                              <TableCell>{eachComment?.commentedBy || '---'}</TableCell>
                              <TableCell>{eachComment?.commentedByType || '---'}</TableCell>
                            </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableCell>
                <TableCell align={'right'}>
                  <Button
                      variant={'outlined'}
                      disabled={!row?.endDate}
                      onClick={()=>{
                        setEditMgCook(true);
                        setEditData(row);
                      }}
                  >
                    {'Edit'}
                  </Button>
                  <Button
                      sx={{ml: 2}}
                      variant={'contained'}
                      onClick={()=>{
                        setAddComment(true);
                        setEditData(row);
                      }}
                  >
                    {'Add Comment'}
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {editMgCook && (
          <CreateMgCookDialog
              callBack={props.callBack}
              cookId={props.cookId}
              isCreate={false}
              open={editMgCook}
              setOpen={setEditMgCook}
              data={editData}
          />
      )}
      {addComment && (
          <Dialog open={addComment} onClose={() => (setAddComment(false))}>
            <DialogTitle>
              Add Comment
            </DialogTitle>
            <DialogContent>
              <TextField
                label={'Comment'}
                onChange={(event: any) => {
                  setComment(event.target.value);
                }}
                sx={{width: 300}}
                multiline
                rows={3}
                value={comment}
                fullWidth={true}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {setAddComment(false)}} color={'error'}>
                Cancel
              </Button>
              <Button onClick={handleAddComment} color={'primary'} variant={'contained'}>
                Add
              </Button>
            </DialogActions>
          </Dialog>
      )}
    </TableContainer>
  );
};

export default CookSalaryList;
