import React from "react";
import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";
import CustomReferenceField from "../Common/CustomReferenceField";

const CouponMetaShowV2 = () => {
  return (
    <Show>
      <div style={{ display: "grid", gridTemplateColumns: "30% 40% 30%" }}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="couponCode" />
          <TextField source="couponDescription" />
          <TextField source="couponHeader" />
          <TextField source="couponType" />
          <TextField source="couponValue" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <DateField source="createdAt" />
          <CustomReferenceField
            label="Created By"
            listName={"AdminUsers"}
            source={"createdBy"}
          />
          <DateField source="updatedAt" />
          <TextField source="createdByEmail" />
          <DateField showTime source="startsAt" />
          <DateField showTime source="expiresAt" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="maximumDiscountAmount" />
          <TextField source="maximumUsageLimit" />
          <TextField source="minimumOrderValue" />
          <TextField source="totalTimesUsed" />
          <TextField source="uniqueCustomerUsed" />
          <TextField source="usageLimitPerCustomer" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="isActive" />
          <TextField source="productType" />
          <TextField source="reason" />
          <TextField source="visibleOnApp" />
        </SimpleShowLayout>
        <SimpleShowLayout>
          <TextField source="userSet" />
          <TextField source="areaSet" />
        </SimpleShowLayout>
      </div>
    </Show>
  );
};

export default CouponMetaShowV2;
