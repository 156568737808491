import * as React from 'react';
import { Close } from "@mui/icons-material";
import {
    Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField
} from "@mui/material";
import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CookService } from "../../services/CookServices";
import { CustomSnackbarTypes } from "./CustomSnackBar";
import {MgCookResponse} from "../../interfaces/Cook/MgCookResponse";

interface InputProps {
    setOpen: Dispatch<SetStateAction<boolean>>;
    open: boolean;
    isCreate: boolean;
    cookId: number;
    callBack: () => void
    data?: MgCookResponse | null;
}
const cookService = new CookService();
export const CreateMgCookDialog = (props: InputProps) => {
    const [salary, setSalary] = useState<number>(0);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');

    const checkAndProcessData = async () => {
        let payload: {cookId?: number, salary: number, startDate: string, endDate?: string} = {
            startDate,
            salary,
        }
        // foe edit scenario cookId should not be there
        if(!props?.data){
            payload = {
                ...payload,
                cookId: props.cookId,
            }
        }
        // for create scenario endDate should not be there
        if(!props.isCreate){
           payload = {
               ...payload,
               endDate: endDate,
           }
        }
        // the data will exist only when we edit it
        if(props?.data){
            // for edit scenario
            return await cookService.editMgCook(props.data.id ,payload)
        } else {
            // for create scenario
            return await cookService.createMgCook(payload)
        }
    };

    const handleSubmit = () => {
        if(salary <= 0){
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Salary must be provided",
                type: CustomSnackbarTypes.ERROR
            });
            return;
        }
        if(!props.isCreate && endDate === ''){
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "End date must be provided",
                type: CustomSnackbarTypes.ERROR
            });
            return;
        }
        if(startDate === ''){
            EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Start date must be provided",
                type: CustomSnackbarTypes.ERROR
            });
            return;
        }
        checkAndProcessData()
            .then(() => {
                setSalary(0);
                setStartDate('');
                setEndDate('');
                props.callBack();
                handleClose();
                EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: props.isCreate ? 'Created Successfully' : 'Updated Successfully',
                    type: CustomSnackbarTypes.SUCCESS
                });
            })
    };
    const handleClose = () => {
        props.setOpen(false);
    }

    useEffect(()=>{
        if(!props?.data) return;
        setSalary(props.data?.salary);
        // @ts-ignore
        setStartDate(props.data?.startDate);
        // @ts-ignore
        setEndDate(props.data?.endDate);
    },[props?.data])
    return (
        <Dialog onClose={handleClose} open={props.open} scroll={"paper"} maxWidth="xl">
            <DialogTitle>{props.isCreate ? 'Create Mg Cook Salary' : 'Update Mg Cook Salary'}</DialogTitle>
            <DialogContent>
              <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 10, right: 10 }}> <Close /> </IconButton>
              <Grid container columns={1} spacing={1} sx={{ padding: "10px", width: 300 }}>
                  <Grid sm={12} md={12} xs={12} item>
                      <TextField
                          id="salary"
                          label={props.isCreate ? 'Salary' : 'New Salary'}
                          type="number"
                          fullWidth
                          variant={'outlined'}
                          value={salary}
                          onChange={(e: any)=>{setSalary(e.target.value)}}
                          // sx={{ width: 220 }}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                  </Grid>
                  {!props.isCreate &&
                    <Grid sm={12} md={12} xs={12} item>
                      <TextField
                        id="end-date"
                        label="Previous salary End Date"
                        type="date"
                        fullWidth
                        variant={'outlined'}
                        value={endDate}
                        onChange={(e: any)=>{setEndDate(e.target.value)}}
                        // sx={{ width: 220 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                      />
                    </Grid>
                  }
                  <Grid sm={12} md={12} xs={12} item>
                      <TextField
                          id="start-date"
                          label={props.isCreate ? 'Salary Start Date' : 'New Salary Start Date'}
                          type="date"
                          fullWidth
                          variant={'outlined'}
                          value={startDate}
                          onChange={(e: any)=>{setStartDate(e.target.value)}}
                          // sx={{ width: 220 }}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                  </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    size={"large"}>
                    Submit
                </Button>
                <Button
                    variant="contained"
                    color={"error"}
                    onClick={handleClose}
                    size={"large"} >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
