import { FilterAlt, FilterAltOff } from "@mui/icons-material";
import { Box, Button, Card, CardContent, InputLabel } from "@mui/material";
import {
    ArrayField,
    CreateButton, Datagrid,
    DatagridConfigurable,
    DateField,
    DateInput,
    EditButton,
    ExportButton, FilterLiveSearch,
    FunctionField,
    List,
    NumberField,
    RaRecord,
    SelectInput,
    ShowButton,
    SimpleList,
    TextField, TopToolbar, useRecordContext, WrapperField,
    FilterList, FilterListItem,
} from "react-admin";
import { TimeEnum } from "../../enums/TimeEnum";
import { TrialsV3Status } from "../../enums/TrialsV3Status";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setTrialSideFilter } from "../../store/reducers/customSelect";
import CustomReferenceField from "../Common/CustomReferenceField";
import {MetabaseDasboardId} from "../../enums/MetabsaeDashboardId";
import MetabaseTransactionButton from "../Common/MetabaseDashboardButton";
import { CityEnum, CityIdMapping } from "../../enums/CityEnum";
import { CkTextField } from "../Common/ReactAdminWrapper/FieldWrapper/CkTextField";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import { useMediaQuery } from "@mui/material";
import moment from "moment";
import {PaymentStatusEnum} from "../../enums/PaymentStatusEnum";
import MetabaseCustomerDashboard from "../Common/MetabaseCustomerDashboard";
import Timer from "../Common/Timer";
import { SelectColumnsButton } from "../Common/ReactAdminWrapper/SelectColumnsButton";
import { FindCookStatus } from "../../enums/FindCookStatusEnum";
import {AllocationType} from "../../enums/AllocationType";
import TrackerBtn from "../Common/TrackerBtn";
import { useEffect, useState } from "react";
import RosterService from "../../services/RosterService";


const rosterService = new RosterService();

const EditButtonView = () => {
    let record = useRecordContext();
    if ([TrialsV3Status.PENDING, TrialsV3Status.COOK_ALLOCATED].includes(record.status)) {
        return (
            <EditButton />
        )
    }
    return null
}

const FilterSidebar = () => {
    return (
        <Box
            sx={{
                marginTop: "15px",
                display: {
                    xs: 'none',
                    sm: 'block'
                },
                order: -1,
                width: '17em',
                marginRight: '1em',
            }}
        >
            <Card
            style={{
            maxHeight: '60%',
            overflow: 'auto'
            }}>
                <CardContent>
                    <InputLabel >Cutomer Name</InputLabel>
                    <FilterLiveSearch source="customerName" />
                    <InputLabel >Cutomer Phone</InputLabel>
                    <FilterLiveSearch source="customerPhoneNumber" />
                    <InputLabel >Cook Name</InputLabel>
                    <FilterLiveSearch source="cookName" />
                    <InputLabel >Cook Phone</InputLabel>
                    <FilterLiveSearch source="cookPhoneNumber" />
                    <InputLabel >Trial Owner Name</InputLabel>
                    <FilterLiveSearch source="trialOwnerName" />
                    <InputLabel >Sector</InputLabel>
                    <FilterLiveSearch source="sector" />
                    <InputLabel >Cancelled By(Customer/admin)</InputLabel>
                    <FilterLiveSearch source="cancelledBy"/>
                    <InputLabel >Cook Accepted</InputLabel>
                    <FilterLiveSearch source="numberOfCookAccepted" />
                    <InputLabel >Shortlisted Cook</InputLabel>
                    <FilterLiveSearch source="numberOfCookAllocated" />
                    <FilterList label="Cook Acknowledge" icon={''}>
                        <FilterListItem label="True" value={{ cookAcknowledged: "true" }}/>
                        <FilterListItem label="False" value={{ cookAcknowledged: "false" }} />
                    </FilterList>
                    <InputLabel >Non-Allocated Trial(true/false)</InputLabel>
                    <FilterLiveSearch source="localityId" />
                </CardContent>
            </Card>
        </Box>
    )
};

const ListActions = (props: any) => {
    const dispatch = useAppDispatch();
    return (
        <TopToolbar>
            <SelectColumnsButton callback={() => {}} />
            {!props.promotorFlag && <>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <MetabaseTransactionButton dashboardId={MetabaseDasboardId.TRIALS} />
            </div>

                <Button variant="text"
                  size={"small"}
                  onClick={() => {
                    dispatch(setTrialSideFilter({
                      sideFilterShow: !props.openSideFilter
                    }))
                  }}
                  startIcon={props.openSideFilter ? <FilterAlt /> : <FilterAltOff />} color="primary"
                >
                  Filter
                </Button>
                <ExportButton />
            </>}
            <CreateButton />
        </TopToolbar>
    );
};
const Trials = (props: any) => {
    const showSideFilter = useAppSelector((s) => s.customSelect.value.sideFilterShow)
    const userDetails = useAppSelector((state:any) => state?.userDetails?.value)
    const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

    const promotorFlag = (userDetails.adminUser.accessGroups as any)?.includes(UserGroupsEnum.PROMOTOR)
    const[owners,setOwners]=useState<any>([])


    const filters = [
        <SelectInput
            label="Filter by status"
            source="status"
            alwaysOn
            emptyText="Clear Filter"
            choices={
                Object.values(TrialsV3Status)
                    .map(key => {
                        return {
                            id: key,
                            name: key
                        }
                    })
            }
        />,
        <SelectInput
            label="Payment Status"
            source="paymentStatus"
            alwaysOn
            emptyText="Clear Filter"
            choices={
                Object.values(PaymentStatusEnum)
                    .map(key => {
                        return {
                            id: key,
                            name: key
                        }
                    })
            }
        />,
        <SelectInput
            label="Pre Checked In"
            source="preCheckedInAt"
            alwaysOn
            emptyText="Clear Filter"
            choices={[
                {
                    id: "YES",
                    name: 'Yes'
                },
                {
                    id: "NO",
                    name: 'No'
                }
            ]}
        />,
        <DateInput label="Date" source="date" alwaysOn />,
        <SelectInput
            label="Time"
            source="time"
            alwaysOn
            emptyText="Clear Filter"
            choices={
                Object.values(TimeEnum)
                    .map(value => {
                        return {
                            id: value,
                            name: value
                        }
                    })
            }
        />,
        <SelectInput
            label="Trial Type"
            source="prId"
            alwaysOn
            emptyText="Clear Filter"
            choices={[
                { id: 'Pr_Trial', name: 'PR Trial' },
                { id: 'Normal_Trial', name: 'Normal Trial' }
            ]}
        />,
        <SelectInput
            label="Find Cook Status"
            source="findCookStatus"
            alwaysOn
            emptyText="Clear Filter"
            choices={
                Object.values(FindCookStatus)
                .map(value => {
                    return {
                        id: value,
                        name: value
                    }
                })
            }
        />,
        <SelectInput
            label="City"
            source="cityId"
            alwaysOn
            emptyText="Clear Filter"
            choices={
                Object.values(CityEnum)
                    .map(key => {
                        return {
                            id: CityIdMapping[key],
                            name: key
                        }
                    })
            }
        />,
        <SelectInput
            label="Filter by Allocation Type"
            source="allocationType"
            alwaysOn
            emptyText="Clear Filter"
            choices={
                Object.values(AllocationType)
                    .map(key => {
                        return {
                            id: key,
                            name: key
                        }
                    })
            }
        />,
        <SelectInput
            label="Discovery Trial"
            source="isPartOfDiscovery"
            alwaysOn
            emptyText="Clear Filter"
            choices={
                [{id: true, name: 'True'}, {id: false, name: 'False'}]
            }
        />,
        <SelectInput
          label="Owner Name"
          source="trialOwner"
          alwaysOn
          sx={{ width: "180px" }}
          emptyText="Clear Filter"
          choices={owners ? owners.map((owner: any) => ({
            id: owner?.adminId,
            name: owner?.adminDetails?.name,
          })) : []}
        />,
    ];
    useEffect(() => {
        rosterService
          .getAllEmployeesForRoster("COOK_PROFILE_LEADS")
          .then((res) => {
            setOwners(res);
          })
      }, [])

    

    return (
      <List
        {...props}
        className="toolbar-custom-css"
        filters={!promotorFlag ? filters : <></>}
        sort={{ field: "id", order: "DESC" }}
        actions={<ListActions openSideFilter={showSideFilter} promotorFlag={promotorFlag}  />}
        aside={showSideFilter ? <FilterSidebar /> : null}
      >
        {isSmall ? (
          <SimpleList
            primaryText={(record) => <span>Cx Name: {record.customerName}</span>}
            secondaryText={(record) => <span>Time: {record.time}</span>}
            tertiaryText={(record) => <span>Date: {moment(record.date).format("DD MMM YYYY")}</span>}
            linkType={(record, id)=> `${id}/show`}
          />
        ) : (
            <DatagridConfigurable bulkActionButtons={false}>
                <FunctionField label="Edit" render={()=>(!promotorFlag ? <EditButtonView/> : <></>)}/>
                <FunctionField label="Show" render={()=><ShowButton />}/>
                <NumberField source="id" />
                <TextField label={'CX Name'} source="customerName" sortable={false} />
                <MetabaseCustomerDashboard label="Customer Dashboard" />
                <TrackerBtn label={'View Tracker'} />
                <TextField source="totalPrice" sortable={false} />
                <CustomReferenceField label="House" listName={"Houses"} source={"houseId"} />
                <WrapperField label="Address">
                    <FunctionField
                        emptyText="Not Available"
                        label="Address"
                        render={(record: {
                            house: { address: any; cityName: any; localityName: any; areaName: any; };
                        }) =>
                            [record?.house?.address, record?.house?.localityName, record?.house?.areaName, record?.house?.cityName]?.filter((each) => each !== null).join(', ')
                        }
                    />
                    {/* <TextField width="30%" source="house.address" />
                    <TextField width="30%" source="house.locality" />
                    <TextField width="30%" source="house.city" /> */}
                </WrapperField>
                <FunctionField
                    emptyText="Not Available"
                    label="Status"
                    render={(record:any) =>
                        <>
                        <div>{record?.status}</div>
                        {record?.preCheckedInAt&&record?.status===TrialsV3Status.COOK_CONFIRMED?<div>PRE CHECKED IN</div>:<></>}
                        </>
                    }
                /> 
                <TextField label={'Sector'} source="areaName" />
                <TextField label={'Locality'} source="localityName" />
                <TextField label={'City'} source="cityName" />
                <DateField
                    options={{ day: "numeric", month: "short", year: "numeric" }}
                    source="date"
                />
                <TextField source="time" label={'Trial Time'}/>
                <CkTextField source="isCheckInRangeValid" label={'Valid Checkin Location'} objMap={{'true': 'Verified', 'false': 'Fraud', 'null': 'Pending'}} />
                <CkTextField source="isCheckOutRangeValid" label={'Valid CheckOut Location'} objMap={{'true': 'Verified', 'false': 'Fraud', 'null': 'Pending'}} />
                <ArrayField textAlign="center" label="Meal Mapping" source="mealMapping">
                    <Datagrid bulkActionButtons={false} style={{ minWidth: "250px" }}>
                        <TextField source="slot" label=""/>
                        <TextField
                            label=""
                            source="time"
                            style={{ width: "61px", display: "inlineBlock" }}
                        />
                        <TextField label="" source="bufferMinutes" />
                    </Datagrid>
                </ArrayField>
                <TextField label={'Bundle Plan'} source="bundlePlan" />
                <TextField label={'Gender Preference'} source="genderPreference" />
                <TextField label={'Admin Notes'} source="adminNotes" />
                <TextField label={'Trial Allocator Name'} source="trialAllocatorName" />
                <TextField label={'Cancellation Reason'} source="cancellationReason" />
                <TextField label={'Not Converted Reason'} source="notConvertedReason" />
                <TextField source="paymentStatus" />
                <CustomReferenceField label="Found Cook Id" listName={"Cooks"} source={"foundCookId"}/>
                <TextField source="findCookTimes" />
                <TextField source="findCookStatus" />
                <FunctionField label="Time Since Trial Created" render={(record: RaRecord) => {
                    if (moment().isAfter(moment(`${record?.date}:${record?.time}`))) return <span>Expired</span>
                    return <Timer startTime={moment(record?.createdAt).toDate()} endTime={moment(`${record?.date}:${record?.time}`).toDate()} />
                }} />
                <FunctionField label="Time Since FindCook" render={(record: RaRecord) => {
                    if (!record?.findCookStartDateTime) return <span>Not Started</span>
                    return <Timer startTime={moment(record?.findCookStartDateTime).toDate()} endTime={moment(`${record?.date}:${record?.time}`).toDate()} />
                }} />
                <TextField source="findCookOwnerId" emptyText="Not Available"/>
                <TextField source="findCookOwnerName" emptyText="Not Available"/>
            </DatagridConfigurable>
          )
        }
      </List>
    );
};

export default Trials;
