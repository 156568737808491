import { BooleanField, Datagrid, DateField, List, ReferenceField, ShowButton, TextField, TextInput } from "react-admin";
import { useAppSelector } from "../../store/hooks";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";

interface AreaListProps{
  isMultiSelect?: boolean;
}

const filters =  [
    <TextInput label="Search by area Name" source="name" alwaysOn />,
    <TextInput label="Search by city id" source="cityId" alwaysOn />,
]

const AreaList = (props:AreaListProps) => {
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);
    const checkMultiSelect = () => {
      return props.isMultiSelect?<></>:false
    }
    return (
        <List filters={filters}>
            <Datagrid bulkActionButtons={checkMultiSelect()}>
                {
                    isCustomSelectOpen &&
                    <ResourceSelectDialogButton />
                }
                <TextField source="id" />
                <TextField source="name" />
                <ReferenceField source="cityId" link="show" reference="Cities">
                    <TextField source="name" />
                </ReferenceField>
                <BooleanField source="isServiceable" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <ShowButton />
            </Datagrid>
        </List>
    )
}

export default AreaList;