import { GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import {ChefForPartyResponse} from "../interfaces/ChefForParty/ChefForPartyResponse";
import httpClient from "../HttpClient";
import {CategoryResponse} from "../interfaces/StarChefVisit/CategoryResponse";
import { CouponProductType } from "../enums/CouponTypeEnum";
import Op from "../interfaces/SequalizeFilters/SequalizeTypes";
import moment from "moment";

class ChefForPartyService {

    processDateRangeFilter(filters: any) {
        let createdAt;
        if (filters?.startDate) {
            createdAt = {
                [Op.gte.toString()]: moment(filters?.startDate)
            }
        }
        if (filters?.endDate) {
            if (createdAt) {
                createdAt = {
                    ...createdAt,
                    [Op.lte.toString()]: moment(filters?.endDate)
                }
            } else {
                createdAt = {
                    [Op.lte.toString()]: moment(filters?.endDate)
                }
            }
        }
        return {createdAt};
    }

    async fetchOneChefForParty(id: number): Promise<GetOneResult<ChefForPartyResponse>> {
        let data = await HttpClient.get(`/centaurus/chef-for-party/${id}`);
        return data;
    }

    async fetchAllChefForPart(limit: number, offset: number, filters = {}, sort = {}): Promise<GetListResult<ChefForPartyResponse>> {
        let defaultFilters = ConstructSequelizeFilters(filters, ['customerPhone','cityName'], ['status','tokenAmountPaymentStatus','remainingAmountPaymentStatus', 'time', 'date'])
        let customFilters = this.processDateRangeFilter(filters);
        filters = {
            ...defaultFilters,
            ...customFilters,
        }
        let data = await HttpClient.get('/centaurus/chef-for-party', {
            params: {
                limit,
                offset,
                filters,
                sort,
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }

    async createChefForPartyService(data: any): Promise<ChefForPartyResponse> {
        let res = await HttpClient.post(`/centaurus/chef-for-party`, data);
        return res?.data;
    }

    async editChefForPartyService(id: number,data: any): Promise<ChefForPartyResponse> {
        let res = await HttpClient.patch(`/centaurus/chef-for-party/${id}/edit`, data);
        return res?.data;
    }

    async assignCookToChefForParty(entityId: number, payload : any){
        let data = await httpClient.patch(`/centaurus/chef-for-party/${entityId}/assign-cook`, payload)
        return data?.data;
    }

    async approvalChefForParty(id : number, payload : any){
        let data = await httpClient.post(`/centaurus/chef-for-party/${id}/approval`, payload)
        return data?.data;
    }

    async addDIshChefForParty(id: number, payload: any): Promise<ChefForPartyResponse> {
        let data = await HttpClient.post(`/centaurus/chef-for-party/${id}/add-dish`, payload);
        return data?.data;
    }

    async getAllCategory(): Promise<GetListResult<CategoryResponse>> {
        let data = await HttpClient.get('/cuisine/categories');
        return data?.data;
    }

    async getAllDishesForCategory(categoryId: number): Promise<GetListResult<CategoryResponse>> {
        let data = await HttpClient.get(`/cuisine/categories/${categoryId}`);
        return data?.data;
    }

    async getAllDishesForTypeAndCategory(categoryId: number, mealType: string): Promise<GetListResult<CategoryResponse>> {
        let data = await HttpClient.get(`/cuisine/categories/${categoryId}`, { params : { mealType } });
        return data?.data;
    }

    async cancelChefForParty(id: number, payload: any): Promise<ChefForPartyResponse> {
        let data = await HttpClient.post(`/centaurus/chef-for-party/${id}`, payload);
        return data?.data;
    }

    async updateChefForPartyOrderAmount(id: number, orderId: number, payload: any): Promise<ChefForPartyResponse> {
        let data = await HttpClient.patch(`/centaurus/chef-for-party/${id}/order/${orderId}/edit-price`, payload);
        return data?.data;
    }

    async reScheduleChefForParty(id: number, payload: any): Promise<ChefForPartyResponse> {
        let data = await HttpClient.post(`/centaurus/chef-for-party/${id}/reschedule`, payload);
        return data?.data;
    }

    async updateAllocationComment(id: number, payload: any): Promise<ChefForPartyResponse> {
        let data = await HttpClient.patch(`/centaurus/chef-for-party/${id}/allocation-comment`, {
            type: 'ALLOCATOR_COMMENT',
            ...payload,
        });
        return data?.data;
    }

    async updateCustomerComment(id: number, payload: any): Promise<ChefForPartyResponse> {
        let data = await HttpClient.patch(`/centaurus/chef-for-party/${id}/customer-comment`, {
            type: 'CUSTOMER_COMMENT',
            ...payload,
        });
        return data?.data;
    }

    async updateSupplyComment(id: number, payload: any): Promise<ChefForPartyResponse> {
        let data = await HttpClient.patch(`/centaurus/chef-for-party/${id}/supply-comment`, {
            type: 'SUPPLY_COMMENT',
            ...payload,
        });
        return data?.data;
    }

    async sendPaymentLink(id: number, orderId: number): Promise<GetOneResult<ChefForPartyResponse>> {
        let data = await HttpClient.post(`/centaurus/chef-for-party/${id}/order/${orderId}/send-payment-link`);
        return data?.data;
    }

    async initiateRefund(id: number, orderId: number, payload: any): Promise<GetOneResult<ChefForPartyResponse>> {
        let data = await HttpClient.post(`/centaurus/chef-for-party/${id}/order/${orderId}/refund`, payload);
        return data?.data;
    }

    async calculateDynamicPrice(payload: any): Promise<GetOneResult<ChefForPartyResponse>> {
        let data = await HttpClient.post(`/customer/chef-for-party/calculate-price`, payload);
        return data?.data;
    }

    async getAllCoupon(houseId: number){
        let data = await HttpClient.get(`/centaurus/v4/coupon/${CouponProductType.CHEF_FOR_PARTY}/houses/${houseId}`);
        return data?.data;
    }

    async changeCoupon(partyId: number, payload: any){
        let data = await HttpClient.post(`/centaurus/chef-for-party/${partyId}/change-coupon`, payload);
        return data?.data;
    }

    async removeDish(partyId: number, dishId: number){
        let data = await HttpClient.delete(`/centaurus/chef-for-party/${partyId}/dish/${dishId}`);
        return data?.data;
    }

    async removeCook(partyId: number, cookId: number){
        let data = await HttpClient.delete(`/centaurus/chef-for-party/${partyId}/cook/${cookId}`);
        return data?.data;
    }

    async markAsFulFilled(partyId: number){
        let data = await HttpClient.post(`/centaurus/chef-for-party/${partyId}/mark-fulfilled`);
        return data?.data;
    }

    async markAsOnGoing(partyId: number){
        let data = await HttpClient.post(`/centaurus/chef-for-party/${partyId}/mark-ongoing`);
        return data?.data;
    }

    async getChefForPartyForHouse(houseId: number,limit: number, offset: number, filters = {}, sort = {}): Promise<GetListResult<ChefForPartyResponse>> {
        let data = await HttpClient.get(`/centaurus/chef-for-party/house/${houseId}`, {
            params: {
                limit,
                offset,
                filters,
                sort,
            }
        });
        return {
            data: (!!data && !!data.data) ? data.data.data : [],
            total: (!!data && !!data.data) ? data.data.count : 0
        }
    }


}

export default ChefForPartyService;
