import Kalend, { CalendarView } from "kalend"; // import component
import "kalend/dist/styles/index.css"; // import styles
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Link,
  TextField,
  Box, Select,
  FormControl,
  InputLabel,
  MenuItem, Typography
} from "@mui/material";
import { Authenticated } from "ra-core";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { DatePicker } from "@mui/x-date-pickers";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { CityEnum, CityIdMapping } from "../../enums/CityEnum";
import {CookService} from "../../services/CookServices";
import './index.css';
import {OneMealService} from "../../services/OneMealService";
import {TrialsService} from "../../services/TrialServices";
import UserBundleService from "../../services/BundleService";
import {OneMeal} from "../../interfaces/OneMeal/OneMeal";
import {KalendColors} from "../../constants/KalendColors";
import {OneMealServiceStatus} from "../../enums/OneMealServiceStatus";
import CityService from "../../services/CityService";
import CityResponse from "../../interfaces/City/CityResponse";
import AreaResponse from "../../interfaces/Area/AreaResponse";
import AreaService from "../../services/AreaService";

const cookService = new CookService();
const oneMealService = new OneMealService();
const trialsService = new TrialsService();
const userBundleService = new UserBundleService();
const cityService = new CityService();
const areaService = new AreaService();
const currentDate = localStorage.getItem('omsTimeTableDateFilter') ? moment(localStorage.getItem('omsTimeTableDateFilter')).toDate() : moment().toDate();

const legends = [
  {color: 'darkgrey', label: 'Leaves/COOK_SUBSCRIPTION_V3/TRIALS_V3'},
  {color: 'green', label: 'OMS FULFILLED'},
  {color: 'blue', label: 'COOK_ALLOCATED, COOK_CONFIRMED before OMS Start time'},
  {color: 'red', label: 'COOK_ALLOCATED, COOK_CONFIRMED after OMS Start time'},
  {color: 'black', label: 'PENDING OMS'},
];

const OmsTimeTable = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(currentDate);
  const [dialog, setDialog] = useState<boolean>(false);
  const [details, setDetails] = useState<any>();
  const [cookOmsMapping, setCookOmsMapping] = useState<any>({});
  const [cityList, setCityList] = useState<CityResponse[]>([]);
  const [selectCity, setSelectCity] = useState<any>(null);
  const [allAreaList, setAllAreaList] = useState<AreaResponse[]>([]);
  const [areaList, setAreaList] = useState<AreaResponse[]>([]);
  const [selectAreaId, setSelectAreaId] = useState<number | null>(null);

  function mergeContinuousTimeSlots(timeSlots: any[]) {
    // Sort the array based on the startAt time
    // @ts-ignore
    timeSlots.sort((a: { startAt: string | number | Date; }, b: { startAt: string | number | Date; }) => new Date(a.startAt) - new Date(b.startAt));

    const mergedSlots = [];
    let currentSlot = timeSlots[0];

    if(!currentSlot) return [];

    for (let i = 1; i < timeSlots.length; i++) {
      const currentEnd = new Date(currentSlot.endAt);
      const nextStart = new Date(timeSlots[i].startAt);
      const currentModelName = currentSlot.referenceModelName;
      const nextModelName = timeSlots[i].referenceModelName;
      const currentReferenceId = currentSlot.referenceId;
      const nextReferenceId = timeSlots[i].referenceId;

      if (currentEnd >= nextStart && currentModelName === nextModelName && currentReferenceId === nextReferenceId) {
        // Merge the current slot with the next slot
        currentSlot.endAt = timeSlots[i].endAt;
        currentSlot.referenceModelName = timeSlots[i].referenceModelName;
        currentSlot.referenceId = timeSlots[i].referenceId;
      } else {
        // Current slot is not continuous, push it to the merged slots
        currentSlot.startAt = moment(currentSlot.startAt)
            .add(0.001, "hours")
            .format();
        mergedSlots.push(currentSlot);
        currentSlot = timeSlots[i];
      }
    }
    if(currentSlot?.isLeave === true) {
      currentSlot.endAt = moment(currentSlot.endAt)
          .subtract(0.001, "hours")
          .format();
    }
    // Push the last slot to the merged slots
    mergedSlots.push(currentSlot);
    return mergedSlots;
  }

  const getColor = (eachSlot: any) => {
    if(eachSlot?.referenceModelName === 'COOK_SUBSCRIPTION_V3') {
      return  'darkgrey';
    } else if(eachSlot?.referenceModelName === 'TRIALS_V3') {
      return  'darkgrey';
    } else if(eachSlot?.referenceModelName === 'ONE_MEAL_SERVICE') {
      const currentDate = moment(new Date(), "YYYY-MM-DD HH:mm:ss");
      const slotDateTime = moment(
          moment(eachSlot.date).format('YYYY-MM-DD') + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm"), "YYYY-MM-DD HH:mm:ss"
      ).format();
      if(eachSlot?.serviceStatus === OneMealServiceStatus.FULFILLED){
        return 'green';
      }
      if([OneMealServiceStatus.COOK_ALLOCATED, OneMealServiceStatus.COOK_CONFIRMED].includes(eachSlot?.serviceStatus) && !currentDate.isAfter(slotDateTime)){
        return 'blue'
      }
      if([OneMealServiceStatus.COOK_ALLOCATED, OneMealServiceStatus.COOK_CONFIRMED].includes(eachSlot?.serviceStatus) && currentDate.isAfter(slotDateTime) && eachSlot?.checkInTime === null){
        return 'red'
      }
      if([OneMealServiceStatus.ONGOING].includes(eachSlot?.serviceStatus) && eachSlot?.checkOutTime === null){
        return 'red'
      }
      return  "yellow";
    } else if(eachSlot?.leaveMarked !== null) {
      return  'darkgrey';
    } else if(OneMealServiceStatus.COOK_ALLOCATED) {
      return  'black';
    }
  };

  const getAllPendingOms = useCallback(
      (start?: Date) => {
        // setCookOmsMapping({});
        let date =  moment(start).format("YYYY-MM-DD");
        let filters : any = {date, paymentStatus: 'PAID'};
        if(selectCity) {
          filters.cityId = selectCity
        }
        if(selectAreaId) {
          filters.areaId = selectAreaId
        }
        oneMealService
            .getAllOneMealService(1000, 0, filters, {})
            .then((res) => {
              const uniqueCook = new Set();
              let tempCookOmsMapping: any = {};
              res.data.forEach((obj: OneMeal) => {
                if (!["CANCELLED","RESCHEDULED"].includes(obj.status)) {
                  let requiredDetails: any = {
                    summary: "",
                    id: NaN,
                    startAt: "",
                    endAt: "",
                    color: "",
                    heading: "",
                    cookId: "",
                    referenceId: "",
                    referenceModelName: "",
                    isLeave: false,
                    cookDetails: null,
                  };
                  requiredDetails.omsDetails = obj;
                  requiredDetails.summary = `${obj?.id}_OME_MEAL_SERVICE`;
                  uniqueCook.add(obj.cook && obj?.cook.id);
                  requiredDetails.id = obj?.id;
                  requiredDetails.startAt = moment(
                      moment(obj.date).format('YYYY-MM-DD') + "T" + moment(obj.time, "h:mm A").format("HH:mm")
                  ).format();
                  requiredDetails.referenceModelName = 'ONE_MEAL_SERVICE';
                  requiredDetails.referenceId = obj?.id;
                  requiredDetails.endAt = moment(requiredDetails.startAt)
                      .add(1.5, "hours")
                      .format();
                  let index = obj.cook
                      ? Array.from(uniqueCook).indexOf(obj?.cook.id) %
                      Array.from(uniqueCook).length
                      : -1;
                  requiredDetails.color =
                      index === -1 ? "black" : KalendColors[index];
                  if (obj.cook === null) {
                    if (tempCookOmsMapping[0]) {
                      tempCookOmsMapping[0].push(requiredDetails);
                    } else {
                      tempCookOmsMapping[0] = [requiredDetails];
                    }
                  }
                }
              });
              setCookOmsMapping((preData: any) => {
                return { ...preData, ...tempCookOmsMapping }
              });
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
      },
      [selectCity, selectAreaId]
  );

  const getAllCooks = useCallback(() => {
    let selectedDate =  moment(date).format("YYYY-MM-DD");
    let filters : any = {date: selectedDate};
    if(selectCity) {
      filters.cityId = selectCity
    }
    if(selectAreaId) {
      filters.areaId = selectAreaId
    }
    cookService.getAllCooksAndSlotsForCalender(filters).then((res) => {
      const uniqueCook = new Set();
      let tempCookOmsMapping: any = {};
      let tempDetails: any = {};
      [...res].forEach((obj: any, i: number) => {
        const timeSlots: any[] = [];
          obj?.calender?.forEach((eachSlot: any) => {
            let requiredDetails: any = {
              summary: "",
              id: NaN,
              startAt: "",
              endAt: "",
              color: "",
              heading: "",
              cookId: "",
              referenceId: "",
              referenceModelName: "",
              isLeave: false,
              cookDetails: {},
            };
            requiredDetails.heading = `${obj?.cook?.firstName} ${obj?.cook?.lastName}`;
            requiredDetails.cookId = obj?.cook?.cookId;
            requiredDetails.summary = eachSlot?.referenceModelName ? `${eachSlot?.referenceId}_${eachSlot?.referenceModelName}` : 'LEAVE';
            requiredDetails.referenceId = eachSlot?.referenceId;
            requiredDetails.referenceModelName = eachSlot?.referenceModelName;
            requiredDetails.cookDetails = obj?.cook;
            uniqueCook.add(obj?.cook?.cookId);
            requiredDetails.id = eachSlot?.id;
            requiredDetails.startAt = moment(
                eachSlot.date + "T" + moment(eachSlot.timeSlot, "h:mm A").format("HH:mm")
            ).format();
            requiredDetails.endAt = moment(requiredDetails.startAt)
                .add(0.5, "hours")
                .format();
            requiredDetails.color = getColor(eachSlot);
            tempDetails = {...requiredDetails};
            if(eachSlot?.leaveMarked !== null){
              requiredDetails.isLeave = true;
            }
            if (eachSlot?.referenceModelName !== null || eachSlot?.leaveMarked !== null) {
              if(eachSlot?.referenceModelName !== ''){
                timeSlots.push(requiredDetails);
              }
            }
          })
        let mergedSlots = mergeContinuousTimeSlots(timeSlots);
          if(mergedSlots.length === 0){
            tempDetails.startAt = '';
            tempDetails.endAt = '';
            mergedSlots = [tempDetails];
          }
        if (obj.cookId !== null) {
          if (tempCookOmsMapping[obj?.cook?.cookId]) {
            tempCookOmsMapping[obj?.cook?.cookId].push(...mergedSlots);
          } else {
            tempCookOmsMapping[obj?.cook?.cookId] = [...mergedSlots];
          }
        }
      });
      setCookOmsMapping((tempData: any) => {
        return {...tempData, ...tempCookOmsMapping }
      });
      setLoading(false);
    })
  }, [date, selectCity, selectAreaId]);

  const DataContainer = ({title = '', value = '---'}: {title: string, value: string}) => {
    return(
        <div>
          <b style={{ minWidth: "170px", display: "inline-block" }}>
            {title}
          </b>
          <span>{value}</span>
        </div>
    )
  };

  const DialogDetails = (props: any) => {
    let cook = props?.data?.cookDetails;
    const [data, setData] = useState<any>(null);
    const [redirectLink, setRedirectLink] = useState('')
    useEffect(() => {
      if(props?.data?.referenceModelName === 'ONE_MEAL_SERVICE'){
        setRedirectLink(`/#/OneMealService/${props?.data?.referenceId}/show`);
        oneMealService.getOneMealService(props?.data?.referenceId).then((res: any) => {
          setData(res?.data);
        })
      } else if(props?.data?.referenceModelName === 'TRIALS_V3'){
        setRedirectLink(`/#/Trials/${props?.data?.referenceId}/show`);
        trialsService.getTrialsById(props?.data?.referenceId).then((res: any) => {
          setData(res?.data);
        })
      } else if(props?.data?.referenceModelName === 'COOK_SUBSCRIPTION_V3') {
        cookService.getSubscriptionForACook(props?.data?.referenceId).then((data: any) => {
          setRedirectLink(`/#/UserBundles/${data?.userBundleMappingId}/show/7`);
          userBundleService.fetchOneUserBundleMapping(data?.userBundleMappingId).then((res: any) => {
            setData(res?.data);
          })
        })
      }
    }, [props?.data?.referenceId, props?.data?.referenceModelName]);
    return (
      <div>
        <Dialog
          onClose={props.handleClose}
          open={props.open}
          scroll={"paper"}
          maxWidth="xl"
        >
          <DialogTitle>
            <b>{props?.data?.referenceModelName}</b>
            <Button
                variant={'contained'}
                sx={{borderRadius: 30, fontSize: '10px', ml: 2}}
                size={'small'}
                component={Link}
                href={redirectLink}
            >
              {'View Full Details'}
            </Button>
          </DialogTitle>
          <DialogContent
            style={{ display: "flex", flexDirection: "column", width: "400px" }}
          >
            <DataContainer title={'Status:'} value={data?.status} />
            {cook && (
              <div>
                <DataContainer title={'Cook Id:'} value={cook?.cookId} />
                <DataContainer title={'Cook Name:'} value={cook?.firstName + " " + cook?.lastName} />
                <DataContainer title={'Cook PhoneNo:'} value={data?.cookPhoneNumber} />
                <DataContainer title={'Onboarding Status:'} value={cook?.onboardingStatus} />
              </div>
            )}
            <DataContainer title={'Customer Name:'} value={data?.customerName} />
            <DataContainer title={'Customer PhoneNo:'} value={data?.customerPhoneNumber} />
            <DataContainer title={'City:'} value={data?.cityName} />
            <DataContainer title={'Area:'} value={data?.areaName} />
            <DataContainer title={'Locality:'} value={data?.localityName} />
            <DataContainer title={'Customer Address:'} value={data?.house?.address + " " + data?.house?.locality + " " + data?.house?.city} />
            <DataContainer title={'Total People:'} value={data?.totalPeople} />
            <DataContainer title={'Allocator Name:'} value={data?.allocatorName} />
            <DataContainer title={'Date:'} value={moment(data?.date).format('DD-MM-YYYY')} />
            <DataContainer title={'Time:'} value={data?.time} />
            <DataContainer title={'Check In Time:'} value={data?.checkInTime} />
            <DataContainer title={'Customer Feedback:'} value={data?.customerFeedback} />
            <DataContainer title={'Check Out Time:'} value={data?.checkOutTime} />
            <DataContainer title={'Customer Comments:'} value={data?.customerComments?.[0]?.comment} />
            <DataContainer title={'Customer Rating:'} value={data?.customerRating} />
          </DialogContent>
          <DialogActions style={{ marginTop: "12px" }}>
            <Button
              variant="contained"
              color={"error"}
              onClick={() => {
                props.handleClose(false);
              }}
              size={"large"}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const onEventClick = (data: any) => {
    if(data?.referenceId) {
      setDialog(true);
    }
    setDetails(data);
  };

  // const getMenuItems = (enumData: string[]) => {
  //   return Object.values(enumData).map((e) => (
  //     <MenuItem key={e} value={e}>
  //       {e}
  //     </MenuItem>
  //   ))
  // }

  useEffect(() => {
    cityService.fetchAllCities(100, 0, {}, {}).then((res) => {
      setCityList(res?.data);
    });
    areaService.fetchAllAreas(100, 0, {}, {}).then((res) => {
      setAllAreaList(res?.data);
      setAreaList(res?.data);
    })
  }, [])

  useEffect(() => {
    if(!selectCity) {
      setAreaList(allAreaList);
      return;
    };
    setAreaList([]);
    setSelectAreaId(null);
    areaService.fetchAllAreas(100, 0, {
      cityId: selectCity,
    }, {}).then((res) => {
      setAreaList(res?.data);
    })
  }, [allAreaList, selectCity])

  useEffect(() => {
    setCookOmsMapping({});
    getAllCooks();
    getAllPendingOms(date);
    const interval = setInterval(() => {
      getAllCooks();
      getAllPendingOms(date);
    }, 1000 * 60 * 5);
  return () => {
    clearInterval(interval);
  }
  }, [date, getAllCooks, getAllPendingOms]);

  return (
    <Authenticated>
      <>
        <DialogDetails
          open={dialog}
          handleClose={() => setDialog(false)}
          data={details}
        />
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "200%",
              minWidth: "0",
              overflowX: "scroll",
            }}
          >
            <div style={{ marginTop: "15px", margin: "15px" }}>
              <form>
                <FormControl sx={{ minWidth: 140, marginRight: 2 }}>
                  <InputLabel id="for_Type">City</InputLabel>
                  <Select
                    labelId="for_Type"
                    id="Type"
                    label="City"
                    value={selectCity}
                    onChange={(event) => {
                      let value: any = event.target.value
                      setSelectCity(value);
                    }}
                  >
                    <MenuItem key={'ALL_CITY'} value={0}>
                      {'ALL_CITIES'}
                    </MenuItem>
                    {cityList.map((eachCity) => (
                        <MenuItem key={eachCity?.name} value={eachCity?.id}>
                          {eachCity?.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 140, marginRight: 2 }}>
                  <InputLabel id="for_Type">Area</InputLabel>
                  <Select
                    labelId="for_Type"
                    id="Type"
                    label="Area"
                    value={selectAreaId}
                    onChange={(event) => {
                      let value: any = event.target.value
                      setSelectAreaId(value);
                    }}
                  >
                    {!selectCity && (
                        <MenuItem key={'ALL_CITY'} value={0}>
                          {'ALL_AREAS'}
                        </MenuItem>
                    )}
                    {areaList.map((eachCity) => (
                        <MenuItem key={eachCity?.name} value={eachCity?.id}>
                          {eachCity?.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/*<ArrowBackIosIcon*/}
                {/*  style={{ padding: "15px" }}*/}
                {/*  onClick={() =>*/}
                {/*    setDate((e) => moment(e).subtract(1, "d").toDate())*/}
                {/*  }*/}
                {/*/>*/}
                <DatePicker
                  mask=""
                  label="Select Date"
                  inputFormat="dd MMM yyyy"
                  value={date}
                  onChange={(newValue: any) => {
                    setDate(newValue);
                  }}
                  renderInput={(params) => {
                    return <TextField {...params} />;
                  }}
                  onAccept={
                    (newValue: any) => {
                      localStorage.setItem("omsTimeTableDateFilter", moment(newValue).toString());
                    }
                  }
                />
                <ArrowForwardIosIcon
                  style={{ padding: "15px" }}
                  onClick={() => setDate((e) => moment(e).add(1, "d").toDate())}
                />
                {/*<FormControl sx={{ minWidth: 140 }}>*/}
                {/*  <InputLabel id="for_Type">Status</InputLabel>*/}
                {/*  <Select*/}
                {/*    labelId="for_Type"*/}
                {/*    id="Type"*/}
                {/*    label="Status"*/}
                {/*    value={paymentStatus || OmsTimeTableStatusEnum.ALL}*/}
                {/*    onChange={(event) => {*/}
                {/*      if (event.target.value === OmsTimeTableStatusEnum.ALL) {*/}
                {/*        setPaymentStatus(null);*/}
                {/*        return;*/}
                {/*      }*/}
                {/*      setPaymentStatus(event.target.value);*/}
                {/*    }}*/}
                {/*  >*/}
                {/*  {*/}
                {/*    getMenuItems(Object.values(OmsTimeTableStatusEnum))*/}
                {/*  }*/}
                {/*  </Select>*/}
                {/*</FormControl>*/}
                <Button sx={{marginLeft: "1rem"}} size="small" variant="outlined" onClick={() => {
                  // setPaymentStatus(null);
                  setSelectCity(0);
                  setSelectAreaId(0);
                  setDate(new Date());
                }}>Reset</Button>
              </form>
            </div>
            {/*<Box m={2} display={'flex'} flexDirection={'row-reverse'}>*/}
            {/*  {legends?.map((eachLegend) => (*/}
            {/*      <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>*/}
            {/*        <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} mr={1} />*/}
            {/*        <Typography>*/}
            {/*          {eachLegend?.label}*/}
            {/*        </Typography>*/}
            {/*      </Box>*/}
            {/*  ))}*/}
            {/*</Box>*/}
            <div
              style={{ display: "flex", height: "100%", overflow: "scroll" }}
            >
              {Object.keys(cookOmsMapping).length !== 0 &&
                Object.entries(cookOmsMapping).map(([key, value]: any) => (
                  <div style={{ textAlign: "center" }} key={key} className={'Calendar__wrapper wrapper'}>
                    {key === "0"
                      ? <Box fontWeight={700} fontSize={13} padding={'6px'} marginLeft={'30px'}>Pending OMS</Box>
                      : <Button
                            // variant={'contained'}
                            sx={{borderRadius: 30, fontSize: '13px', ml: 2}}
                            size={'small'}
                            component={Link}
                            href={`/#/Cooks/${value[0]?.cookId}/show`}
                        >
                          {`${value[0]?.heading}`}
                        </Button>}
                    <Kalend
                      kalendRef={props.kalendRef}
                      initialView={CalendarView.DAY}
                      disabledViews={[
                        CalendarView.AGENDA,
                        CalendarView.THREE_DAYS,
                        CalendarView.MONTH,
                        CalendarView.WEEK,
                      ]}
                      timeFormat={'12'}
                      onEventClick={onEventClick}
                      events={value}
                      initialDate={moment(date).format("YYYY-MM-DD")}
                      hourHeight={60}
                      disabledDragging={true}
                      onStateChange={props.onStateChange}
                      selectedView={props.selectedView}
                      showTimeLine={true}
                      isDark={false}
                      autoScroll={false}
                    />
                  </div>
                ))}
            </div>
            <Box display={'flex'} flexDirection={'row'}>
              <Box m={2} display={'flex'} flexDirection={'column'}>
                {[legends[0], legends[1]]?.map((eachLegend) => (
                    <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>
                      <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} m={0.5} mr={1} />
                      <Typography fontSize={12}>
                        {eachLegend?.label}
                      </Typography>
                    </Box>
                ))}
              </Box>
              <Box m={2} display={'flex'} flexDirection={'column'}>
                {[legends[2], legends[3]]?.map((eachLegend) => (
                    <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>
                      <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} m={0.5} mr={1} />
                      <Typography fontSize={12}>
                        {eachLegend?.label}
                      </Typography>
                    </Box>
                ))}
              </Box>
              <Box m={2} display={'flex'} flexDirection={'column'}>
                {[legends[4]]?.map((eachLegend) => (
                    <Box sx={{display: 'flex', alignItems: 'center'}} id={eachLegend?.label}>
                      <Box sx={{width: 20, height: 20, backgroundColor: eachLegend?.color, borderRadius: 1}} m={0.5} mr={1} />
                      <Typography fontSize={12}>
                        {eachLegend?.label}
                      </Typography>
                    </Box>
                ))}
              </Box>
            </Box>
          </div>
        )}
      </>
    </Authenticated>
  );
};
export default OmsTimeTable;
