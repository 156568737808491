import {
  Button, Checkbox,
  FormControl, FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useState } from "react";
import { Create } from "react-admin";
import { BundleGenderPreference } from "../../enums/common";
import { ShortTimeEnum } from "../../enums/ShortTimeEnum";
import { OneMealService } from "../../services/OneMealService";
import CommonDialogForUserAndHouse from "../Common/CommonDialogUserAndHouse";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import HouseList from "../Houses/HouseList";
import TransactionButton from "../Common/TransactionButton";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { useAppDispatch } from "../../store/hooks";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import { useNavigate } from "react-router-dom";

const oneMealService = new OneMealService();

const OneMealServiceCreate = () => {
  const [time, setTime] = useState<string>("");
  const [totalPeople, setTotalPeople] = useState<number>(0);
  const [date, setDate] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [hasOptedPayAfterService, setHasOptedPayAfterService] = useState(false);
  const [houseId, setHouseId] = useState<any>(null);
  const [genderPreference, setGenderPreference] = useState<string>("");
  const [openHouseDialog, setOpenHouseDialog] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  const handleSelectHouse = () => {
    setOpenHouseDialog(true);
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const handleOpen = () => {
    handleSelectHouse();
  };

  const handleCloseHouse = (record: any) => {
    setOpenHouseDialog(false);
    setHouseId(record?.id);
  };

  const handleSubmit = () => {
    oneMealService
      .createOneMealService({
        houseId,
        genderPreference,
        totalPeople,
        time,
        date: moment(date).format("YYYY-MM-DD"),
        hasOptedPayAfterService,
      })
      .then((data) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Success",
          type: CustomSnackbarTypes.SUCCESS,
        });
        if (data?.id) {
          navigate(`/OneMealService/${data?.id}/show`, { replace: true });
        }
      });
  };

  return (
    <Create title={"Create One Meal Service"}>
      <Grid
        container
        columns={1}
        rowSpacing={2}
        style={{ padding: "12px 12px 12px 12px" }}
      >
        <Grid item xs={4}>
          <CommonDialogForUserAndHouse
            open={open}
            setOpen={setOpen}
            setHouse={setHouseId}
          />
          {openHouseDialog && (
            <ResourceSelectDialog
              name={"Houses"}
              list={HouseList}
              onClose={handleCloseHouse}
            />
          )}
          <TransactionButton
            name={"Create customer and House"}
            onClickHandler={() => setOpen(true)}
            style={{ marginLeft: isSmall ? "0" : "75%" }}
          />
        </Grid>
        <Grid item xs={4}>
          <TransactionButton
            name={houseId ? `houseId: ${houseId}` : "Choose House"}
            onClickHandler={() => handleOpen()}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <DatePicker
              label="Date"
              inputFormat="dd MMM yyyy"
              disableMaskedInput
              minDate={new Date()}
              value={date}
              onChange={(value: any) => {
                setDate(moment(value).format("YYYY-MM-DD"));
              }}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="forGenderPreference">Gender Preference</InputLabel>
            <Select
              labelId="forGenderPreference"
              id="genderPreference"
              value={genderPreference}
              label="genderPreference"
              onChange={(event: any) => {
                setGenderPreference(event.target.value);
              }}
            >
              {Object.values(BundleGenderPreference).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="forTotalPeople">Total People</InputLabel>
            <Select
              labelId="forTotalPeople"
              id="totalPeople"
              value={totalPeople}
              label="total People"
              onChange={(event: any) => {
                setTotalPeople(event.target.value);
              }}
            >
              {Object.values(Array.from({ length: 40 }, (_, i) => i + 1)).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl style={{ width: "230px" }}>
            <InputLabel id="forTime">One Meal Time</InputLabel>
            <Select
              labelId="forTime"
              id="time"
              value={time}
              label="One Meal Time"
              onChange={(event: any) => {
                setTime(event.target.value);
              }}
            >
              {Object.values(ShortTimeEnum).map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasOptedPayAfterService}
                onChange={(e) => {
                  setHasOptedPayAfterService(e.target.checked);
                }}
                name="Pay After Service"
              />
            }
            label="Pay After Service"
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmit();
            }}
            size={"large"}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Create>
  );
};

export default OneMealServiceCreate;
