import React, { useState } from 'react';
import { Button, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import CancelCustomerLeave from '../Common/CancelCustomerLeave';
import { UserType } from '../../enums/common';
import UpdateLeaveDisputeResolutionDialog from '../Common/UpdateLeaveDisputeResolutionDialog';
import { useRefresh } from 'react-admin';

const CustomerLeavesListForModule = (props: any) => {
    const [data, setData] = useState();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const[open,setOpen]=useState<boolean>(false)
    const refresh = useRefresh();

    return (
        <>
        <UpdateLeaveDisputeResolutionDialog
          data={data}
          open={open}
          callback={()=>{
            setOpen(false)
            refresh();
          }}
        />
        <div>
            <CancelCustomerLeave open={openDialog} setOpen={setOpenDialog} data={data} refresh={props.refresh} />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Update Leave Dispute Resolution</TableCell>
                            <TableCell>Dispute Raised By</TableCell>
                            <TableCell>Absence Type</TableCell>
                            <TableCell>Dispute Raised By Type</TableCell>
                            <TableCell>User Name</TableCell>
                            <TableCell>User Mobile</TableCell>
                            <TableCell>User Bundle</TableCell>
                            <TableCell>Cook Id</TableCell>
                            <TableCell>Bundle Start Date</TableCell>
                            <TableCell>Bundle End Date</TableCell>
                            <TableCell>Leave Date</TableCell>
                            <TableCell>Leave slot</TableCell>
                            <TableCell>Allowed Leaves</TableCell>
                            <TableCell>Leaves Taken</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Leave type</TableCell>
                            <TableCell>Resolution</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Updated At</TableCell>
                            <TableCell>Cancellation Reason</TableCell>
                            <TableCell>Cancellation Subreason</TableCell>
                            <TableCell>Marked By</TableCell>
                            <TableCell>Cancel Leave</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.map((row: any) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ? {} : (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important' }:{ color: 'green !important' }}scope="row">{row?.id}</TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                {
                                    row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE"?
                                    <Button
                                        variant={'contained'}
                                        color={'error'}
                                        onClick={() => {
                                            setOpen(true)
                                            setData(row)
                                        }}
                                    >
                                        SET
                                    </Button>
                                :row?.userCookLeaveLinks[0]?.disputeResolution}
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {
                                        row?.userCookLeaveLinks[0]?.disputeRaisedBy ? (row?.userCookLeaveLinks[0]?.disputeRaisedByType === UserType.COOK ?
                                            <Link href={`/#/Cooks/${row?.userCookLeaveLinks[0]?.disputeRaisedBy}/show`}>
                                                {row?.userCookLeaveLinks[0]?.disputeRaisedBy}
                                            </Link> :
                                            <Link href={`/#/Customers/${row?.userCookLeaveLinks[0]?.disputeRaisedBy}/show`}>
                                                {row?.userCookLeaveLinks[0]?.disputeRaisedBy}
                                            </Link>) : "-"
                                    }
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>{row?.userCookLeaveLinks[0]?.absenceType ? row?.userCookLeaveLinks[0]?.absenceType:"-"}</TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>{row?.userCookLeaveLinks[0]?.disputeRaisedByType ? row?.userCookLeaveLinks[0]?.disputeRaisedByType:"-"}</TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {
                                        !!row?.userBundleMapping?.house?.customers &&
                                        Array.isArray(row.userBundleMapping.house.customers) &&
                                        row.userBundleMapping.house.customers.map((cust: any) => {
                                            return (
                                                <p key={cust.fname}>{cust.fname}</p>
                                            )
                                        })
                                    }
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {
                                        !!row?.userBundleMapping?.house?.customers &&
                                        Array.isArray(row.userBundleMapping.house.customers) &&
                                        row.userBundleMapping.house.customers.map((cust: any) => {
                                            return (
                                                <p key={cust.mobile}>{cust.mobile}</p>
                                            )
                                        })
                                    }
                                </TableCell>
                                <TableCell>
                                    <Link href={`/#/UserBundles/${row?.userBundleMapping?.id}/show`}>
                                        {row?.userBundleMapping?.id}
                                    </Link>
                                </TableCell>
                                <TableCell sx={{textAlign:"center"}}>
                                    {
                                        row?.cookId?
                                    <Link href={`/#/Cooks/${row?.cookId}/show/6`}>
                                        {row?.cookId}
                                    </Link>:"-"
                                    } 
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {
                                        !!row?.userBundleMapping?.startDate &&
                                        moment(row?.userBundleMapping?.startDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {
                                        !!row?.userBundleMapping?.endDate &&
                                        moment(row?.userBundleMapping?.endDate, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {
                                        !!row?.date &&
                                        moment(row?.date, 'YYYY-MM-DD').format('DD MMM YYYY')
                                    }
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {row?.slot}
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {row?.userBundleMapping?.userBundleMeta?.totalLeaves}
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {row?.userBundleMapping?.userBundleMeta?.takenLeaves}
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {row?.userBundleMapping?.status}
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {row?.leaveType}
                                </TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>
                                    {row?.resolution}
                                </TableCell>
                                
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>{moment(row?.createdAt).format("LLL") || "--"}</TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>{moment(row?.updatedAt).format("LLL") || "--"}</TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>{row?.cancellationReason ? row?.cancellationReason : "-"}</TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>{row?.cancellationSubreason ? row?.cancellationSubreason : "-"}</TableCell>
                                <TableCell sx={!row?.userCookLeaveLinks[0]?.disputeRaisedBy ?{textAlign:"center"}: (row?.userCookLeaveLinks[0]?.disputeRaisedBy && row?.userCookLeaveLinks[0]?.disputeResolution==="ACTIVE")?{ color: 'red !important', textAlign:"center" }:{ color: 'green !important',textAlign:"center"}}>{row?.markedByUserType}</TableCell>
                                <TableCell>
                                    <Button
                                        variant={'contained'}
                                        color={'error'}
                                        onClick={() => {
                                            setOpenDialog(true)
                                            setData(row)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
</>
    );
};

export default CustomerLeavesListForModule;