import React, { useEffect, useState } from "react";
import CakeIcon from "@mui/icons-material/Cake";
import EngineeringIcon from "@mui/icons-material/Engineering";
import FemaleIcon from "@mui/icons-material/Female";
import HistoryIcon from "@mui/icons-material/History";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MemoryIcon from "@mui/icons-material/Memory";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box, Grid, Link, TableCell,
  TableRow, Typography
} from "@mui/material";
import moment from "moment";
import { useRecordContext } from "react-admin";
import { UserType } from "../../enums/common";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import numberToShirtSizeMapping from "../../utils/NumberToShirtSizeMapping";
import CallButton from "../Common/CallButton/CallButton";
import CkMuiTable from "../Common/CkMuiTable/CkMuiTable";
import ImagePreviewerDialog from "../Common/ImagePreviewerDialog";
import JsonViewer from "../Common/JsonViewer";
import { CookService } from "../../services/CookServices";
interface InputProps {
  data: FreshLeadInterface;
}

enum CustomTextFieldEnum {
  LINK = "LINK",
}
const cookService=new CookService();

const CustomTextField = (props: {
  label: string;
  value?: string | number;
  icon?: React.ReactNode;
  type?: CustomTextFieldEnum;
  url?: string;
  isBlank?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <Grid container columnSpacing={2} mt="1rem">
      <Grid item md={6} display="flex" justifyContent="flex-end">
        {props.icon && <>{props.icon}</>}
        <Typography
          variant="body1"
          fontSize="0.875rem"
          lineHeight="1rem"
          textAlign="right"
          fontWeight={700}
          color="initial"
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid item md={6} display="flex" alignItems="center">
        <Box fontSize="0.875rem">
          {props.children ? (
            props.children
          ) : props.type === CustomTextFieldEnum.LINK ? (
            <Link
              href={props.url}
              {...(props.isBlank && { target: "_blank", rel: "noreferrer" })}
            >
              <Typography
                variant="body1"
                fontSize="0.875rem"
                lineHeight="1rem"
                color="initial"
              >{`${props.value ?? "Not Available"}`}</Typography>
            </Link>
          ) : (
            <Typography
              variant="body1"
              fontSize="0.875rem"
              lineHeight="1rem"
              color="initial"
            >{`${props.value !== ""
                ? props.value ?? "Not Available"
                : "Not Available"
              }`}</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

function CustomTableArrayField(data: any) {
  if (data?.data === null) return <p style={{ margin: 0 }}>Not Available</p>;
  return (
    <Box
      maxHeight={400}
      sx={{
        overflow: "auto",
        borderRadius: "0.5rem",
        border: "1px solid #e1e1e1",
      }}
    >
      <CkMuiTable
        sx={{}}
        headers={Object.keys(data?.data[0])}
        rowData={[]}
        isTablePagination={false}
        isStickyHeader={true}
      >
        {data?.data?.map((row: any, index: number) => (
          <TableRow key={"" + index}>
            {Object.keys(data?.data[0]).map((key: string, index: number) => {
              if (key === "timestamp") {
                return (
                  <TableCell key={"" + index} scope="row">
                    {moment(row[key]).format("DD-MM-YYYY")}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell key={"" + index} scope="row">
                    {row[key]}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        ))}
      </CkMuiTable>
    </Box>
  );
}

const CookProfileShow = ({ data }: InputProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const record = useRecordContext();
  const [referredId,setReferredId]=useState<any>(null);


  useEffect(()=>{
    if(data?.referralMobileNumber){
      const filter ={"mobile":data?.referralMobileNumber}
      cookService.getAllCooks(10,0,filter).then((data)=>{
        if(data?.data?.length>0){
          setReferredId(data?.data[0]?.id);
        }
        else{
          setReferredId(null);
        }
      })
    }
    else{
      setReferredId(null);
    }
  },[data?.referralMobileNumber])
  return (
    <>
      <Box
        sx={{ border: "solid 1px #e1e1e1", borderRadius: "8px", mt: "0.5rem" }}
      >
        <Box component="span"></Box>
        <Grid container>
          <Grid item md={3} position="relative" width="100%">
            <Typography
              variant="body1"
              color="initial"
              sx={{ position: "absolute", top: "0.75rem", left: "1rem" }}
            >
              {`ID: ${record?.id}`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "1rem",
              }}
            >
              {!!data?.profilePhoto ? (
                <img
                  src={`${data?.profilePhoto}?${new Date().getTime()}`}
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                  }}
                  alt="Cook"
                  onClick={() => setOpen(true)}
                />
              ) : (
                <>
                  <img
                    title={"Profile Photo Not Available"}
                    src={
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRODZob7ROpqlx8WXwjueNwTOlC6Qum-yMiyfXiFRXkdhkHgybCpdvM1UpaXCL1ycfO8NI&usqp=CAU"
                    }
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                    }}
                    alt="Cook"
                  />
                </>
              )}
              <Typography
                variant="body1"
                fontSize="1.25rem"
                color="initial"
                textTransform="capitalize"
                mt="0.5rem"
              >
                {`Name: ${data?.firstName} ${data?.lastName}`}
              </Typography>
              <Box width="100%">
                <CustomTextField
                  label="Owner"
                  value={data?.owner}
                  type={data?.owner ? CustomTextFieldEnum.LINK : undefined}
                  url={`/#/AdminUsers/${data?.owner}/show`}
                  icon={
                    <PersonIcon
                      sx={{ fontSize: "1.25rem", marginRight: "4px" }}
                    />
                  }
                />
                <CustomTextField
                  label="Mobile"
                  icon={
                    <PhoneIphoneIcon
                      sx={{ fontSize: "1.25rem", marginRight: "4px" }}
                    />
                  }
                >
                  <CallButton
                    toPhoneNumber={record.mobile}
                    userType={UserType.COOK}
                  />
                </CustomTextField>
                <CustomTextField
                  label="WhatsApp No."
                  value={data?.whatsappNumber}
                  icon={
                    <WhatsAppIcon
                      sx={{ fontSize: "1.25rem", marginRight: "4px" }}
                    />
                  }
                />
                <CustomTextField
                  label="Preferred City"
                  value={data?.preferredCity}
                  icon={
                    <EngineeringIcon
                      sx={{ fontSize: "1.25rem", marginRight: "4px" }}
                    />
                  }
                />
                <CustomTextField
                  label="Cooking Since"
                  value={moment(data?.cookingSince).format("YYYY")}
                  icon={
                    <HistoryIcon
                      sx={{ fontSize: "1.25rem", marginRight: "4px" }}
                    />
                  }
                />
                <CustomTextField
                  label="Date of Birth"
                  value={
                    !!data?.dateOfBirth
                      ? moment(data?.dateOfBirth).format("Do MMM YYYY")
                      : "Not Available"
                  }
                  icon={
                    <CakeIcon
                      sx={{ fontSize: "1.25rem", marginRight: "4px" }}
                    />
                  }
                />
                <CustomTextField
                  label="Chef Hub Name"
                  value={data?.hubDetails?.name}
                />
                <CustomTextField
                  label="Chef Hub"
                  value={data?.chefHub}
                  type={data?.chefHub ? CustomTextFieldEnum.LINK : undefined}
                  url={`/#/Hubs/${data?.chefHub}/show`}
                  icon={
                    <MemoryIcon
                      sx={{ fontSize: "1.25rem", marginRight: "4px" }}
                    />
                  }
                />
                <CustomTextField
                  label="Address"
                  value={record?.address}
                  icon={
                    <LocationOnIcon
                      sx={{ fontSize: "1.25rem", marginRight: "4px" }}
                    />
                  }
                />
                <CustomTextField
                  label="Gender"
                  value={data?.gender}
                  icon={
                    <FemaleIcon
                      sx={{ fontSize: "1.25rem", marginRight: "4px" }}
                    />
                  }
                />
                <CustomTextField
                  label="T-Shirt Size"
                  value={numberToShirtSizeMapping(data?.tShirtSize)}
                />
                <CustomTextField label="Source" value={data?.source} />
                <CustomTextField label="Sub-Source" value={data?.subSource} />
                <CustomTextField label="Tier" value={data?.tier} />
                <CustomTextField label="Has fix tier" value={data?.hasFixedTier ? "Yes" : "No"} />
                {/*<CustomTextField*/}
                {/*  label="Blacklisted"*/}
                {/*  value={data?.blackListed ? "Yes" : "No"}*/}
                {/*/>*/}
              </Box>
            </Box>
          </Grid>
          <Grid item md={9} display="flex">
            <Box
              sx={{
                borderRight: "solid 1px #e1e1e1",
                width: "0px",
                height: "100%",
                display: "inline-block",
              }}
            />
            <Grid container spacing={1}>
              <Grid item md={5}>
                <Box m="0.5rem">
                  <CustomTextField
                    label="Cook Work Status"
                    value={data?.workStatus}
                  />
                  <CustomTextField
                    label="Is MG Cook"
                    value={data?.isMgCook ? "Yes" : "No"}
                  />
                  <CustomTextField
                    label="Cook Non-Veg"
                    value={data?.cooksNonVeg ? "Yes" : "No"}
                  />
                  <CustomTextField
                    label="Cuisine Known"
                    value={Array.isArray(data?.cuisineKnown) ? data?.cuisineKnown?.join(", ") : data?.cuisineKnown}
                  />
                  <CustomTextField
                    label="Working Locality"
                    value={Array.isArray(record?.workingLocality) ? record?.workingLocality?.join(", ") : record?.workingLocality}
                  />
                  <CustomTextField
                    label="Cook Category"
                    value={data?.cookCategory}
                  />
                  {/* <CustomTextField label="Cook Type" value={data?.cookType} /> */}
                  <CustomTextField
                    label="Cook Type List"
                    value={Array.isArray(data?.cookTypeList) ? data?.cookTypeList?.join(", ") : data?.cookTypeList}
                  />
                  <CustomTextField
                    label="Request to open account"
                    value={record?.requestToOpenAccount}
                  />

                  <CustomTextField
                    label="Onboarding Status"
                    value={data?.onboardingStatus}
                  />
                  <CustomTextField
                    label="Onboarding Sub-Status"
                    value={data?.onboardingSubStatus}
                  />
                  <CustomTextField
                    label="Verification Status"
                    value={data?.verificationStatus}
                  />
                  <CustomTextField
                    label="Verification Report"
                    isBlank={true}
                    value={
                      data?.verificationReportUrl ? "Report" : "Not Available"
                    }
                    url={data?.verificationReportUrl}
                    type={
                      data?.verificationReportUrl
                        ? CustomTextFieldEnum.LINK
                        : undefined
                    }
                  />
                  <CustomTextField
                    label="Verification Report Severity"
                    value={data?.verificationReportSeverity}
                  />
                  <CustomTextField
                    label="Interested in House Hold Work"
                    value={data?.interestedInHouseholdJobs ? "Yes" : "No"}
                  />
                  <CustomTextField
                    label="Years of experience in house hold work"
                    value={data?.yearsOfExperienceHousehold}
                  />
                  <CustomTextField
                    label="Has Smartphone"
                    value={data?.hasSmartphone ? "Yes" : "No"}
                  />
                  <CustomTextField
                    label="Sub Status Reasoning"
                    value={data?.subStatusReasoning}
                  />
                  <CustomTextField
                    label="Sub Status Sub Reasoning"
                    value={data?.subStatusSubReasoning}
                  />
                  <CustomTextField
                    label="Referral Mobile Number"
                    value={data?.referralMobileNumber?data?.referralMobileNumber:"not avaliable"} 
                    url={`/#/Cooks/${referredId}/show`}  
                    type={data?.referralMobileNumber ? CustomTextFieldEnum.LINK : undefined}                
                  />
                  <CustomTextField
                    label="Owner Email"
                    value={record?.cookFullProfile?.ownerEmail}
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box m="0.5rem">
                  <CustomTextField
                    label="View Cook Profile"
                    isBlank={true}
                    value={record?.slug ? "View" : "Not Available"}
                    url={`https://thechefkart.com/cooks/${record?.slug}/profile`}
                    type={record?.slug ? CustomTextFieldEnum.LINK : undefined}
                  />
                  <CustomTextField
                    label="Languages"
                    value={Array.isArray(data?.languages) ? data?.languages?.join(", ") : record?.workingLocality}
                  />
                  <CustomTextField
                    label="Mode of Travel"
                    value={data?.modeOfTravel}
                  />
                  <CustomTextField label="Religion" value={data?.religion} />
                  <CustomTextField
                    label="Vaccination Status"
                    value={data?.vaccinationStatus}
                  />
                  <CustomTextField
                    label="Father name"
                    value={data?.fathersName}
                  />
                  <CustomTextField
                    label="Emergency contact name"
                    value={data?.emergencyContactName}
                  />
                  <CustomTextField
                    label="Emergency contact no."
                    value={data?.emergencyContactNumber}
                  />
                  <CustomTextField
                    label="Aadhar ID"
                    value={data?.aadharNumber}
                  />
                  <CustomTextField
                    label="Address Line 1"
                    value={data?.permanentAddressLine1}
                  />
                  <CustomTextField
                    label="Address Line 2"
                    value={data?.permanentAddressLine2}
                  />
                  <CustomTextField
                    label="Address City"
                    value={data?.permanentAddressCity}
                  />
                  <CustomTextField
                    label="Address State"
                    value={data?.permanentAddressState}
                  />
                  <CustomTextField
                    label="Address Pincode"
                    value={data?.permanentAddressPinCode}
                  />
                  <CustomTextField
                    label="Address Update At"
                    value={
                      data?.addressUpdatedAt
                        ? new Date(
                          data?.addressUpdatedAt
                        ).toLocaleDateString()
                        : "Not Available"
                    }
                  />
                  <CustomTextField
                    label="Skill Assessment Scheduled At"
                    value={
                      data?.skillAssessmentScheduledAt
                        ? moment(data?.skillAssessmentScheduledAt).format(
                          "DD/MM/YYYY"
                        )
                        : "Not Available"
                    }
                  />
                  <CustomTextField
                    label="Hygiene Rating"
                    value={data?.softSkillsRating?.hygiene}
                  />
                  <CustomTextField
                    label="Behaviour Rating"
                    value={data?.softSkillsRating?.behaviour}
                  />
                  <CustomTextField
                    label="Communication Rating"
                    value={data?.softSkillsRating?.communication}
                  />
                  <CustomTextField
                    label="Regularity and Punctuality Rating"
                    value={data?.softSkillsRating?.regularityAndPunctuality}
                  />
                </Box>
              </Grid>
              <Grid item md={3}>
                <Box m="0.5rem">
                  <CustomTextField
                    label="Aadhar Front"
                    value={data?.aadharFrontPhoto ? "View" : "Not Available"}
                    url={data?.aadharFrontPhoto}
                    isBlank={true}
                    type={
                      data?.aadharFrontPhoto
                        ? CustomTextFieldEnum.LINK
                        : undefined
                    }
                  />
                  <CustomTextField
                    label="Aadhar Back"
                    value={data?.aadharBackPhoto ? "View" : "Not Available"}
                    url={data?.aadharBackPhoto}
                    isBlank={true}
                    type={
                      data?.aadharBackPhoto
                        ? CustomTextFieldEnum.LINK
                        : undefined
                    }
                  />
                  <CustomTextField
                    label="North Indian Final Rating"
                    value={data?.northIndianFinalRating}
                  />
                  <CustomTextField
                    label="South Indian Final Rating"
                    value={data?.southIndianFinalRating}
                  />
                  <CustomTextField
                    label="Chinese Dish Final Rating"
                    value={data?.chineseDishFinalRating}
                  />
                  <CustomTextField
                    label="Continental Dish Final Rating"
                    value={data?.continentalDishFinalRating}
                  />
                  <CustomTextField
                    label="Mexican Dish Final Rating"
                    value={data?.mexicanDishFinalRating}
                  />
                  <CustomTextField
                    label="Thai Dish Final Rating"
                    value={data?.thaiDishFinalRating}
                  />
                  <CustomTextField
                    label="App Training Rating"
                    value={data?.trainingRating?.appTraining}
                  />
                  <CustomTextField
                    label="Communication Training Rating"
                    value={data?.trainingRating?.communications}
                  />
                  <CustomTextField
                    label="Hygiene Training Rating"
                    value={data?.trainingRating?.hygiene}
                  />
                  <CustomTextField
                    label="Kitchen Etiquette Training Rating"
                    value={data?.trainingRating?.kitchenEtiquette}
                  />
                  <CustomTextField
                    label="Situational Training Rating"
                    value={data?.trainingRating?.situational}
                  />
                  <CustomTextField
                    label="Final Training Rating"
                    value={data?.trainingRating?.finalTrainingScore}
                  />
                  <CustomTextField
                    label="Last Activity"
                    value={
                      record?.Last_Activity_Time
                        ? new Date(
                          record?.Last_Activity_Time
                        ).toLocaleDateString()
                        : "Not Available"
                    }
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mt={"1rem"}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography
              variant="body1"
              fontSize="0.875rem"
              fontWeight={700}
              color="initial"
            >
              Comments
            </Typography>
            {data.comments && data.comments.length > 0 ? (
              <CustomTableArrayField label="Comments" data={data?.comments} />
            ) : (
              <CustomTableArrayField label="Comments" data={null} />
            )}
          </Grid>
          <Grid item md={6}>
            <Typography
              variant="body1"
              fontSize="0.875rem"
              fontWeight={700}
              color="initial"
            >
              Kit Distribution
            </Typography>
            {data?.kitDistributionCount &&
              data?.kitDistributionCount.length > 0 ? (
              <CustomTableArrayField
                label="Kit Distribution Count"
                data={data?.kitDistributionCount}
              />
            ) : (
              <p style={{ margin: 0 }}>Not Available</p>
            )}
          </Grid>
        </Grid>
        <JsonViewer data={record} />
      </Box>
      {open && <ImagePreviewerDialog src={data?.profilePhoto} open={open} onClose={() => setOpen(false)} />}
    </>
  );
};

export default CookProfileShow;
