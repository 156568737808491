import {
  useRecordContext,
} from "react-admin";
import {
    Button as MUIButton,
    Stack,
    Box,
    Table,
    TableContainer,
    TableCell,
    TableHead, TableBody, TableRow, TablePagination, FormControl, InputLabel, Select, MenuItem, TextField, Link
} from "@mui/material";
import {ServiceOfferingEnums} from "../../enums/ServiceOfferingEnums";
import React, {useCallback, useEffect, useState} from "react";
import {ShowCookSlotsDialog} from "../Common/ShowCookSlotsDialog";
import {MarkLeaveDialog} from "../Common/MarkLeaveDialog";
import {CookService} from "../../services/CookServices";
import {ShowCookSlcDialog} from "../Common/ShowCookSlcDialog";
import CityService from "../../services/CityService";
import AreaService from "../../services/AreaService";
import CityResponse from "../../interfaces/City/CityResponse";
import AreaResponse from "../../interfaces/Area/AreaResponse";

interface InputProps {
  name: any,
  callback: (data: any) => void,
  color?: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
}

const CustomButton = (props: InputProps) => {
  let record = useRecordContext();
  return (
      <MUIButton
          onClick={() => {
            props.callback(record);
          }}
          variant={'contained'}
          size={"small"}
          color={props.color || "secondary"}
          style={{ width: "max-content", fontSize: "12px" }}
      >
        {props.name}
      </MUIButton>
  )
}

export const RenderCTAButtons = (props: any) => {
  return(
      <Stack direction={"row"} gap={2}>
        <CustomButton
          name={'all SLC'}
          callback={() => {
              props.setSelectedData(props?.data);
              props.setShowSlc(true);
          }}
        />
        <CustomButton
            color={'error'}
            name={'Mark / UnMark leave'}
            callback={() => {
              props.setSelectedData(props?.data);
              props.setMarkLeave(true);
            }}
        />
        <CustomButton
            name={'Show Slots'}
            callback={() => {
              props.setSelectedData(props?.data);
              props.setShowSlots(true);
            }}
        />
      </Stack>
  )
}

const cookService = new CookService();
const cityService = new CityService();
const areaService = new AreaService();

const DailyCooksSlots = (props: any) => {
    const [page, setPage] = React.useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
    const [total, setTotal] = React.useState<number>(0);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    let [selectedData, setSelectedData] = useState<any>(null);
    let [showSlots, setShowSlots] = useState<boolean>(false);
    let [showSLc, setShowSlc] = useState<boolean>(false);
    let [services, setServices] = useState<any>([]);

    const [cityList, setCityList] = useState<CityResponse[]>([]);
    const [selectCity, setSelectCity] = useState<any>(1);
    const [allAreaList, setAllAreaList] = useState<AreaResponse[]>([]);
    const [areaList, setAreaList] = useState<AreaResponse[]>([]);
    const [selectAreaId, setSelectAreaId] = useState<number | null>(null);
    const [cookId, setCookId] = useState<number | string | null>(null);

    let [markLeave, setMarkLeave] = useState<boolean>(false)
    let [data, setData] = useState<any>([]);

    useEffect(() => {
        cityService.fetchAllCities(100, 0, {}, {}).then((res) => {
            setCityList(res?.data);
        });
        areaService.fetchAllAreas(100, 0, {}, {}).then((res) => {
            setAllAreaList(res?.data);
            setAreaList(res?.data);
        })
    }, [])

    useEffect(() => {
        if(!selectCity) {
            setAreaList(allAreaList);
            return;
        }
        setAreaList([]);
        // setSelectAreaId(null);
        areaService.fetchAllAreas(100, 0, {
            cityId: selectCity,
        }, {}).then((res) => {
            setAreaList(res?.data);
            setSelectAreaId(null);
            // setSelectAreaId(res?.data?.[0]?.id || 0);
        })
    }, [allAreaList, selectCity])

    const loadData = useCallback((limit: number, offset: number, filter: any) => {
        cookService.getAllDailyCooks(limit, offset, filter).then((res: any) => {
            setTotal(res?.total || 0);
            setData([...res?.data]);
        })
    }, []);

    useEffect(() => {
        let filter = {};
        if(cookId) {
            filter = {
                cookId
            }
        } else {
            filter = {
                cityId: selectCity,
                serviceId: services?.length ? services : [1, 2, 3, 4],
            }
            if(selectAreaId) {
                filter = {
                    ...filter,
                    areaId: selectAreaId,
                }
            }
        }

       loadData(rowsPerPage, page*rowsPerPage, filter)
    }, [loadData, page, rowsPerPage, services, selectCity, selectAreaId, cookId]);

  return (
    <>
        {showSlots && (
            <ShowCookSlotsDialog
                data={selectedData}
                open={showSlots}
                callback={() => {
                    setShowSlots(false);
                }}
            />
        )}
        {showSLc && (
            <ShowCookSlcDialog
                data={selectedData}
                open={showSLc}
                callback={() => {
                    setShowSlc(false);
                }}
            />
        )}
        {markLeave && (
            <MarkLeaveDialog
                data={selectedData}
                open={markLeave}
                callback={() => {
                    setMarkLeave(false);
                }}
            />
        )}
        <Box m={2}>
            <Box display={'flex'} mb={2}>
                <FormControl sx={{minWidth: 200, marginRight: 2}}>
                    <InputLabel id="demo-simple-select-label">
                        Service
                    </InputLabel>
                    <Select
                        name="Service"
                        label="Service"
                        multiple={true}
                        value={services}
                        onChange={(e) => {
                            setServices(e.target.value as string);
                        }}
                        sx={{width: 250}}
                    >
                        {Object.keys(ServiceOfferingEnums).map((key,index) => (
                            <MenuItem key={key} value={index + 1}>
                                {key}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 140, marginRight: 2 }}>
                    <InputLabel id="for_Type">City</InputLabel>
                    <Select
                        labelId="for_Type"
                        id="Type"
                        label="City"
                        value={selectCity}
                        onChange={(event) => {
                            let value: any = event.target.value
                            setSelectCity(value);
                        }}
                    >
                        {cityList.map((eachCity) => (
                            <MenuItem key={eachCity?.name} value={eachCity?.id}>
                                {eachCity?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ minWidth: 140, marginRight: 2 }}>
                    <InputLabel id="for_Type">Area</InputLabel>
                    <Select
                        labelId="for_Type"
                        id="Type"
                        label="Area"
                        value={selectAreaId}
                        onChange={(event) => {
                            let value: any = event.target.value
                            setSelectAreaId(value);
                        }}
                    >
                        {areaList.map((eachCity) => (
                            <MenuItem key={eachCity?.name} value={eachCity?.id}>
                                {eachCity?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField value={cookId} type={'number'} onChange={(event) => {
                    let val = event.target.value;
                    if(val === '') {
                        setCookId('');
                    } else {
                        if (/^-?\d*\.?\d*$/.test(val) && parseFloat(val) >= 0) {
                            setCookId(Number(event.target.value));
                        }
                    }
                }}
                    sx={{minWidth: 140}}
                    variant={'filled'}
                    label={'Cook Id'}
                    InputProps={{ inputProps: { min: 0 } }}
                />
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Cook Type</TableCell>
                            <TableCell>Service</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((eachRow: any, index: number) => (
                            <TableRow key={`${eachRow?.cook?.id}-${eachRow?.cook?.cookId}-${page*rowsPerPage+index}`}>
                                <TableCell>
                                    <Link
                                        href={`/#/Cooks/${eachRow?.cook?.cookId}/show/`}
                                    >
                                        {eachRow?.cook?.cookId}
                                    </Link>
                                </TableCell>
                                <TableCell>{eachRow?.cook?.firstName + ' ' + eachRow?.cook?.lastName}</TableCell>
                                <TableCell>{eachRow?.cook?.cookType}</TableCell>
                                <TableCell>{Object.keys(ServiceOfferingEnums)?.[eachRow?.serviceId - 1] || '---'}</TableCell>
                                <TableCell>
                                    <RenderCTAButtons
                                        setSelectedData={setSelectedData}
                                        setShowSlots={setShowSlots}
                                        setMarkLeave={setMarkLeave}
                                        setShowSlc={setShowSlc}
                                        data={eachRow?.cook}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={total || 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Box>
    </>
  );
};

export default DailyCooksSlots;
