import {
    ArrayField,
    Datagrid,
    DateField, DateInput,
    List,
    SelectInput,
    TextField,
    TopToolbar,
    ExportButton,
    Labeled,
    ShowButton,
    FilterLiveSearch,
    FunctionField,
} from 'react-admin';
import { TemporaryReplacementStatusEnum } from "../../enums/TemporaryReplacementStatusEnum";
import { ZohoBaseUrl, ZohoModulesUrl } from '../../enums/zoho';
import LinkField from '../Common/LinkField';
import { Box, Button, Card, CardContent, InputLabel, Link } from '@mui/material';
import MetabaseTransactionButton from "../Common/MetabaseDashboardButton"
import { MetabaseDasboardId } from '../../enums/MetabsaeDashboardId';
import { CityEnum, CityIdMapping } from '../../enums/CityEnum';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FilterAlt, FilterAltOff } from '@mui/icons-material';
import { setTemporaryReplacementFilter } from '../../store/reducers/customSelect';
import CallButton from '../Common/CallButton/CallButton';
import { UserType } from '../../enums/common';

const FilterSidebar = () => {
    return (
      <Box
        sx={{
          marginTop: "25px",
          display: {
            xs: "none",
            sm: "block",
          },
          order: -1,
          width: "17em",
          marginRight: "1em",
        }}
      >
        <Card>
          <CardContent>
            <InputLabel>Customer Acknowledge(True/flase)</InputLabel>
            <FilterLiveSearch source="customerAcknowledged"/>
            <InputLabel>Assignee</InputLabel>
            <FilterLiveSearch source="cookAllocatedByEmail"/>
          </CardContent>
        </Card>
      </Box>
    );
  };

const filters = [
    <SelectInput
        label="Status"
        source="status"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(TemporaryReplacementStatusEnum)
                .map(value => {
                    return {
                        id: value,
                        name: value
                    }
                })
        }
    />,
    <DateInput label="Start Date" source="startDate" alwaysOn />,
    <DateInput label="End Date" source="endDate" alwaysOn />,
    <SelectInput
    label="City"
    source="cityId"
    alwaysOn
    emptyText="Clear Filter"
    choices={
        Object.values(CityEnum)
            .map(key => {
                return {
                    id: CityIdMapping[key],
                    name: key
                }
            })
    }
/>, 
];

// const CustomButton = (props: any) => {
//     let record = useRecordContext();
  
//     return (
//       <Button
//         onClick={() => {
//           props.callback(record);
//         }}
//         size={"small"}
//         color={props.color || "secondary"}
//         variant={"contained"}
//         style={{ width: "max-content", fontSize: "12px" }}
//       >
//         {props.name}
//       </Button>
//     );
//   };

  // export const ActionButtons = (props: any) => {
  //   let record = useRecordContext();
  //   const refresh = useRefresh();
  
  //   const fullfilledHandler = (data: any) => {
  //     temporaryReplacement.markedFulfilled(data?.id).then(() => refresh());
  //   };
  
  //   const whatsAppHandler = async (data: any) => {
  //     try {
  //       let msgData: any = await temporaryReplacement.getWhatsAppMsg(data.id);
  //       window.open(
  //         `https://api.whatsapp.com/send?phone=+91${data?.cook?.whatsappNumber}&text=${msgData?.data}`
  //       );
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  
  //   const HandleOngoing = (data: any) => {
  //     temporaryReplacement.markOngoing(data?.id).then(() => {
  //       EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
  //         message: "Successful",
  //         type: CustomSnackbarTypes.SUCCESS,
  //       });
  //       refresh();
  //     });
  //   };
  
  //   return (
  //     <div>
  //       <Stack direction={"row"} gap={2}>
  //         {  [
  //           TemporaryReplacementStatusEnum.ONGOING,
  //           TemporaryReplacementStatusEnum.COOK_ALLOCATED,
  //         ].includes(record?.status) &&
  //         <TransactionButton
  //                       style={{margin: "0px 5px"}}
  //                       name={"Change Cook"}
  //                       onClickHandler={() => {
  //                           props.setData(record);
  //                           props.setOpenAssignCookToTrDialog(true);
  //                       }}
  //                   />}
  //         {record?.status === "PENDING" && (
  //           <CustomButton
  //             color={"primary"}
  //             name={"Assign Cook"}
  //             callback={() => {
  //               props.setData(record);
  //               props.setMultipleTrAllocateDialog(true);
  //             }}
  //           />
  //         )}
  //         {[
  //           TemporaryReplacementStatusEnum.PENDING,
  //           TemporaryReplacementStatusEnum.ONGOING,
  //           TemporaryReplacementStatusEnum.COOK_ALLOCATED,
  //         ].includes(record?.status) && (
  //           <CustomButton
  //             color={"primary"}
  //             name={"Cancel"}
  //             callback={() => {
  //               props.setConfirmDialog(true);
  //               props.setData(record);
  //             }}
  //           />
  //         )}
  //         {record?.status === "PENDING" && (
  //           <CustomButton
  //             color={"primary"}
  //             name={"Change TR Time"}
  //             callback={() => {
  //               props.setChangeTimeDailog(true);
  //               props.setData(record);
  //             }}
  //           />
  //         )}
  //         {[TemporaryReplacementStatusEnum.COOK_ALLOCATED].includes(
  //           record?.status
  //         ) && (
  //           <CustomButton
  //             color={"primary"}
  //             name={"Mark Ongoing"}
  //             callback={() => {
  //               EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
  //                 title: "Are you sure?",
  //                 message: "Are you sure you want to mark ongoing this TR?",
  //                 successLabel: "Confirm",
  //                 failureLabel: "Cancel",
  //                 onClose: (isConfirmed: boolean) =>
  //                   isConfirmed ? HandleOngoing(record) : null,
  //               });
  //             }}
  //           />
  //         )}
  //         {[
  //           TemporaryReplacementStatusEnum.COOK_ALLOCATED,
  //           TemporaryReplacementStatusEnum.ONGOING,
  //         ].includes(record?.status) && (
  //           <CustomButton
  //             color={"primary"}
  //             name={"Mark Fulfilled"}
  //             callback={() => fullfilledHandler(record)}
  //           />
  //         )}
  //         {[
  //           TemporaryReplacementStatusEnum.COOK_ALLOCATED,
  //           TemporaryReplacementStatusEnum.ONGOING,
  //           TemporaryReplacementStatusEnum.FULFILLED,
  //           TemporaryReplacementStatusEnum.PENDING,
  //         ].includes(record?.status) && (
  //           <CustomButton
  //             color={"primary"}
  //             name={"Mark Failed"}
  //             callback={() => {
  //               props.setMarkFailedDialog(true);
  //               props.setData(record);
  //             }}
  //           />
  //         )}
  //         {[
  //         TemporaryReplacementStatusEnum.COOK_ALLOCATED,
  //         TemporaryReplacementStatusEnum.ONGOING,
  //         TemporaryReplacementStatusEnum.FULFILLED,
  //         TemporaryReplacementStatusEnum.PENDING,
  //         TemporaryReplacementStatusEnum.CANCELLED,
  //         TemporaryReplacementStatusEnum.FAILED,
  //       ].includes(record?.status) && record.cook && (
  //           <CustomButton
  //             color={"primary"}
  //             name={"Send WhatsApp"}
  //             callback={() => whatsAppHandler(record)}
  //           />
  //         )}
  //       </Stack>
  //     </div>
  //   );
  // };

const ListActions = (props: any) => {
    const dispatch = useAppDispatch();
    return(
    <TopToolbar>
        <Link href={"#/timetable"} target={'_self'} underline="none">
          <Button variant="text" size={"small"}>
            Open TR Module
          </Button>
        </Link>
        <MetabaseTransactionButton dashboardId={MetabaseDasboardId.TEMPORARY_REPLACEMENT} />
        <Button variant="text" size={"small"}
          onClick={() => {
            dispatch(
              dispatch(setTemporaryReplacementFilter({
                temporaryReplacementSideFilter: !props.openSideFilterShow
              }))
            );
          }}
          startIcon={props.openSideFilterShow ? <FilterAlt /> : <FilterAltOff />} color="primary"
        >
          Filter
        </Button>
        <ExportButton />
    </TopToolbar>
)};

const TemporaryReplacement = (props: any) => {
    const showSideFilter = useAppSelector((s) => s.customSelect.value.temporaryReplacementSideFilter);

    // const refresh = useRefresh();
    // const [data, setData] = useState<any>(null);
    // const [confirmDialog, setConfirmDialog] = useState(false);
    // const [multipleTrAllocateDialog, setMultipleTrAllocateDialog] =
    //   useState(false);
    // const [changeTimeDailog, setChangeTimeDailog] = useState<boolean>(false);
    // const [openAssignCookToTrDialog, setOpenAssignCookToTrDialog] =
    //   useState(false);
    // const [sendMultipleWhatsAppTr, setSendMultipleWhatsAppTr] = useState(false);
    // const [markFailedDialog, setMarkFailedDialog] = useState(false);


  // useEffect(() => {
  //   const cancelHandler = (data: any) => {
  //     temporaryReplacement.markedCancel(data?.id).then(() => {
  //       refresh();
  //       setConfirmDialog(false);
  //     });
  //   };

  //   if (confirmDialog) {
  //     EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
  //       message: " Are you sure you want to cancel this TR?",
  //       onClose: (confirmed: boolean) => {
  //         setConfirmDialog(false);
  //         if (confirmed) {
  //           cancelHandler(data);
  //         }
  //       },
  //       title: "Confirmation",
  //     });
  //   }
  // }, [confirmDialog, data, refresh]);

    return (
        <div>
               {/* {markFailedDialog &&
        <MarkFailedDialogForTR
          data={data}
          open={markFailedDialog}
          setOpen={setMarkFailedDialog}
        />
      }
      {!multipleTrAllocateDialog && !sendMultipleWhatsAppTr && (
        <AssignCookOnTempRep
          data={data}
          open={openAssignCookToTrDialog}
          setOpen={setOpenAssignCookToTrDialog}
        />
      )}
      <ChangeTrTimeDialog
        open={changeTimeDailog}
        handleClose={setChangeTimeDailog}
        data={data}
      />
      {!openAssignCookToTrDialog && (
        <MultipleTemporaryReplacement
          data={data}
          multipleTrAllocateDialog={multipleTrAllocateDialog}
          setMultipleTrAllocateDialog={setMultipleTrAllocateDialog}
          sendMultipleWhatsAppTr={sendMultipleWhatsAppTr}
          setSendMultipleWhatsAppTr={setSendMultipleWhatsAppTr}
        />
      )} */}
            <List {...props} filters={filters} sort={{ field: 'id', order: 'DESC' }} actions={<ListActions openSideFilterShow={showSideFilter}/>} aside={showSideFilter ? <FilterSidebar /> : null}>
                <Datagrid bulkActionButtons={false}>
                <ShowButton/>
                    <TextField label="Tr Id" source="id" />
                    <ArrayField sortable={false} label="User Name" source="userBundleMapping.house.customers">
                        <Datagrid bulkActionButtons={false}>
                            <div>
                            <TextField source="fname" label={''} />
                            <br/>
                            <TextField source="lname" label={''} />
                            </div>
                        </Datagrid>
                    </ArrayField>
                    <ArrayField sortable={false} label="User Mobile" source="userBundleMapping.house.customers">
                        <Datagrid bulkActionButtons={false}>
                            <FunctionField render={(record: any) => <CallButton toPhoneNumber={record?.mobile} userType={UserType.CUSTOMER}/>}/>
                        </Datagrid>
                    </ArrayField>
                    <Labeled label="Address">
                        <div style={{ width: "200px" }}>
                            <TextField sortable={false} source="userBundleMapping.house.address" />
                            <br />
                            <TextField sortable={false} source="userBundleMapping.house.city" />
                            <br />
                            <TextField sortable={false} source="userBundleMapping.house.locality" />
                        </div>
                    </Labeled>
                    <TextField source='cityName' label="City" sortable={false}/>
                    <TextField source='areaName' label="Sector" sortable={true}/>
                    <TextField source="cookId" label={"CookId"} sortable={false} />
                    <TextField source="cook.fname" label={"TR Chef Name"} sortable={false} />
                    <FunctionField label={"TR Chef Mobile"} render={(record: any) => <CallButton toPhoneNumber={record?.cook?.mobile} userType={UserType.COOK}/>}/>
                    <DateField source="checkInTime" label={"Cook CheckIn Time"} sortable={false} showTime/>
                    <DateField source="checkOutTime" label={"Cook CheckOut Time"} sortable={false} showTime/>
                    <DateField options={{ day: 'numeric', month: 'short', year: 'numeric' }} source="date" />
                    <TextField source="time" />
                    <TextField source="slot" sortable={false} />
                    <TextField source="status" sortable={false} />
                    <TextField label="Plan" sortable={false} source="userBundleMapping.userBundleMeta.plan" />
                    <LinkField source='zohoId' href={`${ZohoBaseUrl}${ZohoModulesUrl.TR}`} sourceRoute targetBlank />
                    <TextField source='customerAcknowledged'/>
                    <TextField source='cookAllocatedByEmail'/>
                    <DateField source='createdAt' options={{ day: 'numeric', month: 'short', year: 'numeric', hour: "numeric", minute: 'numeric', hourCycle: 'h12' }} />
                    {/* <ActionButtons
                    setData={setData}
                    setMarkFailedDialog={setMarkFailedDialog}
                    setConfirmDialog={setConfirmDialog}
                    setMultipleTrAllocateDialog={setMultipleTrAllocateDialog}
                    setChangeTimeDailog={setChangeTimeDailog}
                    setOpenAssignCookToTrDialog={setOpenAssignCookToTrDialog}
                    /> */}
                </Datagrid>
            </List>
        </div>
    );
};

export default TemporaryReplacement;
