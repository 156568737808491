import React from 'react';
import {Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import moment from 'moment';

const TransactionV3ListForModule = (props: any) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Currency</TableCell>
                        <TableCell>Order Id</TableCell>
                        <TableCell>Refund amount</TableCell>
                        <TableCell>State</TableCell>
                        <TableCell>User Bundle</TableCell>
                        <TableCell>Razorpay Success Payment Id</TableCell>
                        <TableCell>Payment Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.map((row: any) => (
                        <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell scope="row">{row?.id}</TableCell>
                            <TableCell align="left">{row?.amount}</TableCell>
                            <TableCell align="left">{row?.currency}</TableCell>
                            <TableCell align="left">{row?.razorpayOrderId}</TableCell>
                            <TableCell align="left">{row?.refundAmount}</TableCell>
                            <TableCell align="left">{row?.state}</TableCell>
                            <TableCell align="left">
                                <Link
                                    href={`/#/UserBundles/${row.referenceId}/show`}
                                >
                                    {row?.referenceId}
                                </Link>
                            </TableCell>
                            <TableCell align="left">{row?.razorpaySuccessPaymentId}</TableCell>
                            <TableCell align="left">{moment(row?.updatedAt).format("YYYY/MM/DD HH:MM")}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TransactionV3ListForModule;
