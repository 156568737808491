import { CreateResult, GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import AreaResponse from "../interfaces/Area/AreaResponse";
import CreateArea from "../interfaces/Area/CreateArea";
import ConstructSequelizeFilters from "../utils/ConstructSequelizeFilters";
import { ReferenceModelNameEnums } from "../enums/ReferenceModelNameEnums";
import ServiceResponse from "../interfaces/Service/ServiceResponse";
import { SlcServiceCookMappingEnum } from "../enums/SlcServiceCookMappingEnum";

export default class AreaService {
  async fetchAllAreas(
    limit: number,
    offset: number,
    filters: {},
    sort: {}
  ): Promise<GetListResult<AreaResponse>> {
    filters = ConstructSequelizeFilters(filters, ["name", "cityId"]);
    let data = await HttpClient.get("/centaurus/houses/areas", {
      params: {
        limit,
        offset,
        filters,
        sort,
      },
    });
    return {
      data: !!data && !!data?.data ? data?.data?.data : [],
      total: !!data && !!data?.data ? data?.data?.count : 0,
    };
  }

  async fetchOne(areaId: number): Promise<GetOneResult<AreaResponse>> {
    const area = await HttpClient.get(`/centaurus/houses/areas/${areaId}`);
    return area;
  }

  async getSlcCookServiceMappingForVisit(payload:any):Promise<any>{
    const res= await HttpClient.get(`/houses/get-cook-slc-mapping-by-area-name?serviceId=${payload.serviceId}&referenceModelName=AREA&referenceId=${payload.referenceId}&time=${payload.time}&totalPeople=${payload.totalPeople}&date=${payload.date}&houseId=${payload.houseId}`);
    return res;
  }

  async getAreasByCityId(cityId:number):Promise<any>{

    const areas= await HttpClient.get(`/centaurus/newareas/city/${cityId}`);
    return areas;
    
  }

  async getMany(ids: Array<number>): Promise<GetListResult<AreaResponse>> {
    const data = [];

    for (const id of ids) {
      const res = await this.fetchOne(id);
      data.push(res?.data);
    }

    return {
      data: data,
      total: data?.length,
    };
  }

  async create(payload: CreateArea): Promise<CreateResult<AreaResponse>> {
    const isServiceable = payload.isServiceable === "true" ? true : false;
    const res = await HttpClient.post(
      `/centaurus/houses/${payload.cityId}/area`,
      { name: payload.name, isServiceable: isServiceable }
    );
    return {
      data: res?.data,
    };
  }

  async markAsServiceable(areaId: number): Promise<AreaResponse> {
    const res = await HttpClient.patch(
      `/centaurus/areas/${areaId}/mark-as-serviceable`
    );
    return res?.data;
  }

  async markPayAfterService(areaId: number, payload: any): Promise<AreaResponse> {
    const res = await HttpClient.patch(
      `/centaurus/areas/${areaId}/mark-pay-after-service`,
        payload,
    );
    return res?.data;
  }

  async markAsUnServiceable(areaId: number): Promise<AreaResponse> {
    const res = await HttpClient.patch(
      `/centaurus/areas/${areaId}/mark-as-unserviceable`
    );
    return res?.data;
  }

  async getAllServiceOfferings(payload: {
    referenceId: number;
    referenceModelName: ReferenceModelNameEnums;
  }): Promise<ServiceResponse[]> {
    const res = await HttpClient.get(`/houses/get-offering-by-slc`, {
      params: {
        ...payload,
      },
    });
    return res?.data;
  }

  async getAllServices(): Promise<ServiceResponse[]> {
    const res = await HttpClient.get(`/houses/get-service-offerings`);
    return res?.data;
  }

  async createServiceForSlc(payload: {
    referenceId: number;
    referenceModelName: ReferenceModelNameEnums;
    serviceId: number;
    isServing: boolean;
  }): Promise<ServiceResponse> {
    const res = await HttpClient.post(
      `/houses/create-slc-service-mapping`,
      payload
    );
    return res?.data;
  }

  async changeCookServeForSlc(payload: {
    referenceId: number;
    referenceModelName: ReferenceModelNameEnums;
    serviceId: number;
    isCookServed: boolean;
  }): Promise<ServiceResponse> {
    const res = await HttpClient.patch(`/centaurus/houses/locality/set-cook-served-by-area`, payload);
    return res?.data;
  }

  async updateServiceForSlc(payload: {
    referenceId: number;
    referenceModelName: ReferenceModelNameEnums;
    serviceId: number;
    isServing: boolean;
  }): Promise<ServiceResponse> {
    const res = await HttpClient.patch(
      `/houses/create-slc-service-mapping/${payload?.serviceId}`,
      payload
    );
    return res?.data;
  }

  async getAllSclCooks(params: any): Promise<ServiceResponse> {
    const res = await HttpClient.get(
      `/centaurus/houses/get-cook-slc-mapping-by-area/${params.serviceId}/${params.areaId}`,
      {
        params: {
          ...params?.filters,
        },
      }
    );
    return res?.data;
  }

  async addCooksToSlc(payload: {
    referenceId: number;
    referenceModelName: string;
    serviceId: number;
    cookId: number;
    mappingType: SlcServiceCookMappingEnum;
  }): Promise<ServiceResponse> {
    const res = await HttpClient.post(
      `/centaurus/houses/add-cook-slc-mapping-by-area`,
      payload
    );
    return res?.data;
  }

  async removeSlcCooks(payload: any): Promise<ServiceResponse> {
    const res = await HttpClient.post(
      `/centaurus/houses/delete-cook-slc-mapping-by-area`,
      payload
    );
    return res?.data;
  }

  async editAreaLatLong(id: number, payload: {coordinates: {lat: number, long: number}}){
    const data = await HttpClient.patch(`/centaurus/areas/${id}/update-lat-log`, payload)
    return data?.data
  }

  async getBookingSlotThreshold(filters: any, limit: number, offset: number) {
    const data = await HttpClient.get(`/centaurus/booking-slot-threshold/all?limit=${limit}&offset=${offset}&filters=${filters}`);
    return data?.data;
  }

  async createSlotThreshold(payload: any) {
    const data = await HttpClient.patch(`centaurus/booking-slot-threshold`, payload);
    return data?.data;
  }
}
