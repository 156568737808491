import {DateField, FunctionField, ReferenceField, Show, SimpleShowLayout, TextField, useRecordContext} from "react-admin";


const HouseMetaShow = () => {
  const record = useRecordContext();
  

  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <FunctionField
          emptyText=""
          label="Address"
          render={(record: { address: any; cityName: any; localityName: any; areaName: any;
          }) =>
              [record?.address, record?.localityName, record?.areaName, record?.cityName]?.filter((each) => each !== null).join(', ')
          }
        />
        {/* <TextField source="address" />
        <TextField source="locality" /> */}
        <TextField label="Map Location" source="city" />
        <ReferenceField source="cityId" link="show" reference="Cities">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="areaId" link="show" reference="Areas">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="localityId" link="show" reference="Localities">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="sector" />
        <TextField source="lat" />
        <TextField source="long" />
        <TextField source="otherSlcName" />

        <DateField showTime source="createdAt" />
        <DateField showTime source="updatedAt" />
        {
          record.lat !== null && record.long !== null &&
          (
            <div><a href={`https://www.google.com/maps/search/?api=1&query=${record.lat},${record.long}`} target="_blank" rel="noopener noreferrer"> Go to google maps</a></div>
          )}
      </SimpleShowLayout>

    </Show>
  );
};

export default HouseMetaShow;
