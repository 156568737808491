import { Datagrid, DateField, List, ShowButton, TextField } from "react-admin";
import { useAppSelector } from "../../store/hooks";
import ResourceSelectDialogButton from "../Common/ResourceSelectDialogButton";

export const CityList = () => {
    const isCustomSelectOpen = useAppSelector((state) => state.customSelect.value.open);

    return (
        <List>
            <Datagrid bulkActionButtons={false}>
                {
                    isCustomSelectOpen &&
                    <ResourceSelectDialogButton />
                }
                <TextField source="id" />
                <TextField source="name" />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <ShowButton />
            </Datagrid>
        </List>
    )
};