export enum RazorpayOrderReferenceModelEnums {
    //USER_TO_COOK_PAYMENT = "USER_TO_COOK_PAYMENT",
    TRIAL_V3 = "TRIAL_V3",
    //DUPLICATEPAYMENT = "DUPLICATEPAYMENT",
    USER_BUNDLE_PAYMENTS = "USER_BUNDLE_PAYMENTS",
    ONE_MEAL_SERVICE = "ONE_MEAL_SERVICE",
    //STAR_CLEANER = "STAR_CLEANER",
    DISCOUNT_COUPON_ORDER = "DISCOUNT_COUPON_ORDER",
    //PLATFORM_LEAD = "PLATFORM_LEAD",
    //MARKET_PLACE_ORDER = "MARKET_PLACE_ORDER"
}
