import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select, Stack,
    TextField,
} from "@mui/material";
import React, { useState } from "react";
import UserBundleService from "../../services/BundleService";
import { Create } from "react-admin";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import {BundleGenderPreference, BundlePlan, DynamicPricingSlotEnum} from "../../enums/common";
import { MealMappingInput } from "../Common/MealMappingInput";
import TransactionButton from "../Common/TransactionButton";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import { useAppDispatch } from "../../store/hooks";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import BundleList from "../Bundle/BundleList";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import EventBus from "../../EventBus";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import HouseList from "../Houses/HouseList";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom"
import {TrialsService} from "../../services/TrialServices";

const userBundleService = new UserBundleService();
const trialsService = new TrialsService();

const UserBundleMappingCreate = (props: any) => {
    const dispatch = useAppDispatch();
    const [startDate, setStartDate] = useState<any>(null);
    const [startSlot, setStartSlot] = useState<string>("");
    const [quotedPrice, setQuotedPrice] = useState(0);
    const [plan, setPlan] = useState<string>("");
    const [genderPreference, setGenderPreference] = useState<string>("");
    const [totalPeople, setTotalPeople] = useState<number>(0);
    const [numberOfVisit, setNumberOfVisit] = useState<number>(0);
    const [mealMapping, setMealMapping] = useState<any>([]);
    const [utensils, setUtensils] = useState<any>(false);
    // const [freeTr, setFreeTr] = useState<any>(true);
    const [isQuotedNetPriceDisabled, setIsQuotedNetPriceDisabled] = useState<any>(true);
    const [houseId, setHouseId] = useState<any>(null);
    const [areaId, setAreaId] = useState<any>(null);
    const [bundleId, setBundleId] = useState<any>(null);
    const [cookCut, setCookCut] = useState<string>("");
    const navigate = useNavigate();
    var sortObj: any = { B: 0, L: 1, D: 2 };

    const [selected, setSelected] = useState(NaN);

    const handleSelectHouseOrBundle = () => {
        dispatch(setCustomSelectOpen({
            open: true
        }));
    }

    const handleOpen = (val: number) => {
        setSelected(val)
        handleSelectHouseOrBundle();
    }

    const changePriceToDefaultVal = (defaultPrice: number = 0) => {
        if(quotedPrice === 0) return;
        setQuotedPrice(defaultPrice)
        setCookCut("0");
    };

    const handleCloseBundle = (data: any) => {
        setBundleId(data?.id);
        changePriceToDefaultVal();
    };

    const handleCloseHouse = (record: any) => {
        setHouseId(record?.id);
        setAreaId(record?.areaId);
        changePriceToDefaultVal();
    }
    let setVisitTime = (myIndex: number, data: any) => {
        setMealMapping(
            mealMapping.map((ele: any, index: number) => {
                if (index === myIndex) {
                    return { ...ele, time: data };
                }
                return ele;
            })
        );
        changePriceToDefaultVal();
    };

    let setVisitSlot = (myIndex: number, data: any) => {
        setMealMapping(
            mealMapping.map((ele: any, index: number) => {
                if (index === myIndex) {
                    return { ...ele, slot: data };
                }
                return ele;
            })
        );
        changePriceToDefaultVal();
    };

    let setBufferTime = (myIndex: number, data: any) => {
        setMealMapping(
            mealMapping.map((ele: any, index: number) => {
                if (index === myIndex) {
                    return { ...ele, bufferMinutes: Number(data) };
                }
                return ele;
            })
        );
        changePriceToDefaultVal();
    };

    const MealMappingValidation = (mealMapping: any, numberOfVisit: number) => {
        if (mealMapping.length === numberOfVisit) {
            for (const obj of mealMapping) {
                if (Object.keys(obj).length !== 3) {
                    return true;
                }
            }
        }
        return false;
    };

    const calculatePrice = () => {
        if(
            bundleId &&
            areaId &&
            plan !== '' &&
            startDate &&
            genderPreference !== '' &&
            totalPeople !== 0 &&
            numberOfVisit !== 0 &&
            !MealMappingValidation(mealMapping, numberOfVisit)
        ) {
            trialsService.calculateDynamicPriceByAreaId({
                bundleId,
                areaId,
                totalPeople,
                plan,
                visitsPerDay: numberOfVisit,
                utensils,
                mealMapping,
                includeFreeTr: false,
            }).then((res) => {
                if(res?.price){
                    setQuotedPrice(res.price);
                    setCookCut(res.cookCut);
                }
            })
        }
    };

    const validate = () => {
        if(!houseId){
            showError('Please select a house');
            return false;
        } else if(!bundleId){
            showError('Please select a bundle');
            return false;
        } else if(plan === ''){
            showError('Please select a plan');
            return false;
        } else if(genderPreference === ''){
            showError('Please select a gender preference');
            return false;
        } else if(!startDate){
            showError('Please select a date');
            return false;
        } else if(totalPeople === 0){
            showError('Please select total people');
            return false;
        } else if(numberOfVisit === 0){
            showError('Please select the total number Of Visit');
            return false;
        } else if (MealMappingValidation(mealMapping, numberOfVisit)) {
            showError('Please fill the meal mapping details');
            return false;
        } else if (startSlot === '') {
            showError('Please select start slot');
            return false;
        }
        return true;
    };

    const showError = (title: string) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
            message: title || "Fill the form to continue",
            type: CustomSnackbarTypes.ERROR,
        });
    }

    return (
        <Create title={'Create User orders'}>
            <Grid
                container
                columns={1}
                rowSpacing={1}
                style={{ padding: "12px 12px 12px 12px" }}
            >
                <Grid item xs={4}>
                    <ResourceSelectDialog
                        name={selected === 0 ? "Houses" : "Bundles"}
                        list={selected === 0 ? HouseList : BundleList}
                        onClose={selected === 0 ? handleCloseHouse : handleCloseBundle}
                    />
                    <Stack gap={1}>
                        <Stack gap={1}>
                            <TransactionButton
                                name={
                                    houseId
                                        ? `houseId: ${houseId}`
                                        : "Choose House"
                                }
                                onClickHandler={() => handleOpen(0)}
                            />
                        </Stack>
                        <Stack gap={1}>
                            <TransactionButton
                                name={
                                    bundleId
                                        ? `bundleId: ${bundleId}`
                                        : "Choose Bundle"
                                }
                                onClickHandler={() => handleOpen(1)}
                            />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <FormControl>
                        {
                            process.env.REACT_APP_ENV !== 'production' &&
                            <p style={{color: 'red', margin: '0px', fontSize: '12px'}}>Past dates are not allowed in production</p>
                        }
                        <DatePicker
                            label={`Start Date`}
                            inputFormat="dd MMM yyyy"
                            disableMaskedInput
                            minDate={process.env.REACT_APP_ENV === 'production' ? new Date() : null}
                            value={startDate}
                            onChange={(value: any) => {
                                setStartDate(moment(value).format("YYYY-MM-DD"));
                                changePriceToDefaultVal();
                            }}
                            renderInput={(params: any) => <TextField {...params} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{ width: "230px" }}>
                        <InputLabel id="forBundlePlan">Plan</InputLabel>
                        <Select
                            labelId="forBundlePlan"
                            id="bundlePlan"
                            value={plan}
                            label="Bundle Plan"
                            onChange={(event: any) => {
                                setPlan(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(BundlePlan).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{ width: "230px" }}>
                        <InputLabel id="forGenderPreference">Gender Preference</InputLabel>
                        <Select
                            labelId="forGenderPreference"
                            id="genderPreference"
                            value={genderPreference}
                            label="genderPreference"
                            onChange={(event: any) => {
                                setGenderPreference(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(BundleGenderPreference).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{ width: "230px" }}>
                        <InputLabel id="forTotalPeople">Total People</InputLabel>
                        <Select
                            labelId="forTotalPeople"
                            id="totalPeople"
                            value={totalPeople}
                            label="totalPeople"
                            onChange={(event: any) => {
                                setTotalPeople(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {[2, 3, 4, 5, 6, 7, 8].map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{ width: "230px" }}>
                        <InputLabel id="NumberOfVisit">Number Of Visits</InputLabel>
                        <Select
                            labelId="NumberOfVisit"
                            id="numberOfVisit"
                            label="NumberOfVisits"
                            value={numberOfVisit}
                            onChange={(event: any) => {
                                let numberOfVisit = event.target.value;
                                let mealMappingList = [];
                                for (let i = 0; i < numberOfVisit; i++) {
                                    mealMappingList.push({ ...mealMapping?.[i], bufferMinutes: 30 });
                                }
                                setMealMapping(mealMappingList);
                                setNumberOfVisit(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {[1, 2].map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <div style={{ width: "600px" }}>
                        {!!mealMapping &&
                            Array.isArray(mealMapping) &&
                            mealMapping.length > 0 &&
                            mealMapping.map((value, index) => (
                                <MealMappingInput
                                    key={index}
                                    data={value}
                                    setVisitTime={setVisitTime}
                                    setVisitSlot={setVisitSlot}
                                    setBufferTime={setBufferTime}
                                    index={index}
                                    isDynamicPricing={true}
                                    disabled={{
                                        bufferTime: true,
                                    }}
                                />
                            ))}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{ width: "230px" }}>
                        <InputLabel id="forSlot">Select Start Slot</InputLabel>
                        <Select
                            labelId="forSlot"
                            id="slot"
                            value={startSlot}
                            label="Select Start Slot"
                            onChange={(event: any) => {
                                setStartSlot(event.target.value);
                                changePriceToDefaultVal();
                            }}
                        >
                            {Object.values(DynamicPricingSlotEnum).map((e) => (
                                <MenuItem key={e} value={e}>
                                    {e}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <Box display={'flex'} alignItems={'center'}>
                        <FormControl style={{ width: "230px" }}>
                            <TextField
                                label="Quoted Net Price"
                                id="quotedPrice"
                                value={quotedPrice}
                                disabled={isQuotedNetPriceDisabled}
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                                onChange={(event: any) => {
                                    setQuotedPrice(event.target.value);
                                }}
                            />
                        </FormControl>
                        <TransactionButton
                            name={"Calculate Price"}
                            onClickHandler={() => {
                                if(validate()){
                                    calculatePrice();
                                }
                            }}
                            style={{marginLeft: '30px'}}
                        />
                        <TransactionButton
                            name={"Edit Price Manually"}
                            onClickHandler={() => {
                                if(quotedPrice){
                                    setIsQuotedNetPriceDisabled(false);
                                } else {
                                    showError('Please calculate price first');
                                }
                            }}
                            style={{marginLeft: '30px'}}
                        />
                    </Box>
                </Grid>
                {
                    isQuotedNetPriceDisabled &&
                    <Grid item xs={4}>
                        <FormControl style={{ width: "230px" }}>
                            <TextField
                                disabled={true}
                                label="Cook Cut"
                                id="cookCut"
                                value={cookCut}
                                type="number"
                                InputProps={{ inputProps: { min: 0 } }}
                            />
                        </FormControl>
                    </Grid>
                }
                {/* <Grid item xs={4}>
                    <FormControl>
                        <FormLabel id="freeTr">Include Free Tr</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event: any) => {
                                setFreeTr(event.target.value !== 'false');
                                changePriceToDefaultVal();
                            }}
                            value={freeTr}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Yes"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="No"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid> */}
                <Grid item xs={4}>
                    <FormControl>
                        <FormLabel id="utensils">Utensils</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(event: any) => {
                                setUtensils(event.target.value !== 'false');
                                changePriceToDefaultVal();
                            }}
                            value={utensils}
                        >
                            <FormControlLabel
                                value={true}
                                control={<Radio />}
                                label="Required"
                            />
                            <FormControlLabel
                                value={false}
                                control={<Radio />}
                                label="Not-Required"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        variant="contained"
                        onClick={() => {
                           if(validate()){
                               if(quotedPrice === 0){
                                   showError('Please calculate the price');
                                   return;
                               }
                               userBundleService
                                   .createUserBundleMapping(
                                       {
                                           bundleId,
                                           houseId,
                                           startDate,
                                           startSlot,
                                           quotedPrice: String(quotedPrice),
                                           cookCut: "0.0",
                                           genderPreference,
                                           totalPeople,
                                           plan,
                                           mealMapping,
                                           utensils,
                                           visitsPerDay: mealMapping.length,
                                           includeFreeTr: false,
                                           slots: mealMapping
                                               .map((e: any) => e.slot)
                                               .sort((a: string, b: string) => {
                                                   return sortObj[a[0]] - sortObj[b[0]];
                                               })
                                               .join(""),

                                       },
                                   )
                                   .then((data) => {
                                    if(data?.data.id) {
                                        navigate(`/UserBundles/${data?.data.id}/show`, {replace: true})
                                    }
                                   });
                           }
                        }}
                        size={"large"}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Create>
    );
};

export default UserBundleMappingCreate;
