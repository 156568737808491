import React, { useEffect, useMemo, useState } from 'react';
import { GoogleMap, useJsApiLoader, Polygon, InfoWindow } from '@react-google-maps/api';
import MgAllocationDailog from '../Common/MapComponentDailog';
import AreaService from '../../services/AreaService';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import CityService from '../../services/CityService';

const containerStyle = {
  width: '100%',
  height: '100vh',
};


const options1 = {
  fillColor: 'lightblue',
  fillOpacity: 0.5,
  strokeColor: 'red',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

function MgAllocation() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string,
  });

  const [, setMap] = React.useState(null);
  const [allBoundarys, setAllBoundarys] = React.useState([]);
  const [dialogOpen, setDailogOpen] = React.useState(false);
  const [area, setArea] = React.useState<any>({});
  const [cities, setCities] = React.useState<any>([]);
  const [city, setCity] = React.useState<any>(1);
  const [hoveredBoundary, setHoveredBoundary] = useState<any>(null);
  const [infoWindowPosition, setInfoWindowPosition] = useState<any>(null);
  const [center,setCenter]=useState<any>({ lat: 28.449180, lng: 77.030228 });

  const onPolygonMouseOver = (boundary: any) => {
    setHoveredBoundary(boundary);
    setInfoWindowPosition(boundary.position);
  };

  const onPolygonMouseOut = () => {
    setHoveredBoundary(null);
    setInfoWindowPosition(null);
  };

  const areaService = useMemo(() => new AreaService(), []);
  const cityService = useMemo(() => new CityService(), []);

  useEffect(() => {
    cityService.fetchAllCities(1000, 0, {}, {}).then((res) => {
      setCities(res?.data);
    });
  }, [cityService]);

  useEffect(() => {
    if (city) {
      areaService.getAreasByCityId(city)
        .then(function (response) {
          let areas = response?.data.filter(
            (area: any) => area?.enclosingPolygon && area?.enclosingPolygon?.coordinates.length > 0
          );
          let allAreaCoords: any = [];
          setCenter(
            {
              lat: areas[0]?.centroid?.coordinates[1],
              lng: areas[0]?.centroid?.coordinates[0]
            }
          )
          for (let area of areas) {
            let coordinates = area?.enclosingPolygon?.coordinates[0]; // The array of polygon points
            let polygonPoints = [];
            let center = {
              lat: area?.centroid?.coordinates[1],
              lng: area?.centroid?.coordinates[0],
            }; // Extracting the centroid

            for (let point of coordinates) {
              polygonPoints.push({
                lat: point[1],
                lng: point[0],
              });
            }

            allAreaCoords.push({
              id: area.id,
              name: area.name,
              points: polygonPoints,
              cityId: area.cityId,
              position: center,
            });
          }

          const seen = new Set();
          allAreaCoords = allAreaCoords.filter((area: any) => area.points.length !== 0);
          const filteredArr = allAreaCoords.filter((area: any) => {
            const duplicate = seen.has(area?.name + area?.cityId);
            seen.add(area?.name + area?.cityId);
            return !duplicate;
          });

          setAllBoundarys(filteredArr);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [city, areaService]);

  const onClickHander = (e: any) => {
    setArea(e);
    setDailogOpen(true);
  };

  const onLoad = React.useCallback(function callback(e: any) {
    setMap(e);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <>
      <Box sx={{ width: 200 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">City</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={city}
            label="City"
            onChange={(e) => {
              setCity(e.target.value);
            }}
          >
            {cities &&
              cities.length > 0 &&
              cities.map((city: any) => (
                <MenuItem key={city?.id} value={city?.id}>
                  {city?.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
      <MgAllocationDailog
        data={area}
        open={dialogOpen}
        callback={() => {
          setDailogOpen(false);
        }}
      />
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {allBoundarys.map((e: any) => (
          <div key={`${e.name} cityId-${e.cityId}`}>
            <Polygon
              options={options1}
              paths={e.points}
              onClick={() => onClickHander(e)}
              onMouseOver={() => onPolygonMouseOver(e)}
              onMouseOut={onPolygonMouseOut}
            />
          </div>
        ))}
        {hoveredBoundary && (
        <InfoWindow
          position={infoWindowPosition}
          options={{ disableAutoPan: true }}
        >
          <div style={{fontWeight:"600"}}>{hoveredBoundary.name}</div>
        </InfoWindow>
      )}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default React.memo(MgAllocation);
