import {
  Checkbox,
  FormControl, FormGroup,
  FormLabel, TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import FormControlLabel from "@mui/material/FormControlLabel";
import CityService from "../../services/CityService";
import CityResponse from "../../interfaces/City/CityResponse";

const cityService = new CityService();


interface InputProps {
  cookDetail: FreshLeadInterface;
  onCallback: (data: string[]) => void
}


const PreferredCitySelect = ({ cookDetail, onCallback }: InputProps) => {

  const [allCities, setAllCities] = useState<CityResponse[]>([]);
  const [isOther, setOther] = useState<boolean>(false);
  const [text, setText] = useState<string>('');

  const handleCheckCity = (e: React.ChangeEvent<HTMLInputElement>) => {
    let data: any = cookDetail?.preferredCities || [];
    if(e.target.checked) {
      data.unshift(e.target.value);
    } else {
      data = data.filter((each: string) => each !== e.target.value);
    }
    onCallback(data);
  };

  useEffect(() => {
    if(cookDetail?.preferredCities && allCities?.length) {
      let data = cookDetail?.preferredCities?.[cookDetail?.preferredCities?.length - 1];
      if(data) {
        let city = allCities.find((eachCity) => eachCity?.name === data);
        if(!city) {
          setText(data);
          setOther(true);
        }
      }
    }
  }, [cookDetail?.preferredCities, allCities]);

  useEffect(() => {
    cityService.fetchAllCities(100, 0, {}, {})
      .then((res) => {
        setAllCities(res?.data);
      })
  }, [])

  return (
    <div>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">{`Where do you want to work?`}</FormLabel>
        <FormGroup>
            {allCities?.map((eachCity) => {
                return(
                    <FormControlLabel
                        control={
                            <Checkbox checked={cookDetail?.preferredCities?.includes(eachCity?.name)} onChange={handleCheckCity} value={eachCity?.name} name={eachCity?.name} />
                        }
                        label={eachCity?.name}
                    />
                )
            })}
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isOther}
                        onChange={() => {
                          setOther(!isOther);
                          if(isOther) {
                            let data = cookDetail?.preferredCities;
                            data?.pop();
                            onCallback(data);
                            setText('');
                          } else {
                            onCallback([...cookDetail?.preferredCities, '']);
                          }
                        }}
                        value={isOther}
                        name={'Other'}
                    />
                }
                label={'Other'}
            />
            {isOther && (
                <TextField label={'Enter the city'} onChange={(e: any) => {
                  setText(e.target.value);
                  let data = cookDetail?.preferredCities;
                  data?.pop();
                  onCallback([...data, e.target.value])
                }} value={text} />
            )}
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default PreferredCitySelect;
