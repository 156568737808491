import {
    Box,
    FormControl,
    FormLabel,
    RadioGroup,
    TextField,
} from "@mui/material";
import React, {useEffect, useState} from "react";
import FreshLeadInterface from "../../interfaces/Cook/FreshLeadInterface";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CityResponse from "../../interfaces/City/CityResponse";
import CityService from "../../services/CityService";
import {HubsResponse} from "../../interfaces/Hubs/HubsInterfaces";
import HubServices from "../../services/HubServices";
import Op from "../../interfaces/SequalizeFilters/SequalizeTypes";

const cityService = new CityService();
const hubServices = new HubServices();
interface InputProps {
    cookDetail: FreshLeadInterface;
    onCallback: (data: any) => void
}

const CurrentCitySelect = ({ cookDetail, onCallback }: InputProps) => {

    const [allCities, setAllCities] = useState<CityResponse[]>([]);
    const [allHubs, setAllHubs] = useState<HubsResponse[]>([]);
    const [selectedHub, setSelectedHub] = useState<any>();

    const [isOther, setOther] = useState<boolean>(false);
    const [text, setText] = useState<string>('');

    useEffect(() => {
        cityService.fetchAllCities(100, 0, {}, {})
            .then((res) => {
                setAllCities(res?.data);
            })
    }, []);

    useEffect(() => {
        if(!cookDetail?.preferredCity) {
            return;
        }
        let filter = {"cityName":{[Op.like.toString()]: `%${cookDetail?.preferredCity?.toString()}%`}};
        setAllHubs([]);
        hubServices.getAllHubs(1000, 0, {}, {"field":"name","order":"ASC"}, filter)
            .then((res) => {
                setAllHubs(res?.data);
            })
    }, [cookDetail?.preferredCity]);

    useEffect(() => {
        if(cookDetail?.chefHub) {
            setSelectedHub(cookDetail?.chefHub);
        }
        if(cookDetail?.otherChefHub && cookDetail?.otherChefHub !== '') {
            setOther(true);
            setText(cookDetail?.otherChefHub);
        }
    }, [cookDetail?.chefHub, cookDetail?.otherChefHub]);

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <FormControl>
                <FormLabel>
                    {`In which city do you currently live?`}
                </FormLabel>
                <RadioGroup>
                    {allCities?.map((eachCity) => {
                        return(
                            <FormControlLabel
                                checked={cookDetail?.preferredCity === eachCity?.name}
                                onChange={() => {
                                    onCallback({preferredCity: eachCity?.name})
                                }}
                                value={eachCity?.name}
                                control={<Radio />}
                                label={eachCity?.name}
                            />
                        )
                    })}
                </RadioGroup>
            </FormControl>
            {allHubs?.length ? (
                <FormControl>
                    <FormLabel>
                        {`In which area do you currently live?`}
                    </FormLabel>
                    <RadioGroup>
                        {allHubs?.map((eachHub) => {
                            return(
                                <FormControlLabel
                                    checked={selectedHub === eachHub?.id}
                                    onChange={() => {
                                        setOther(false);
                                        setText('');
                                        setSelectedHub(eachHub?.id);
                                        onCallback({otherChefHub: ''})
                                        onCallback({hubId: eachHub?.id})
                                    }}
                                    value={eachHub?.name}
                                    control={<Radio />}
                                    label={eachHub?.name}
                                />
                            )
                        })}
                        <FormControlLabel
                            checked={isOther}
                            onChange={() => {
                                setOther(true);
                                setText('');
                                setSelectedHub(null);
                                // onCallback('')
                            }}
                            value="Others"
                            control={<Radio />}
                            label="Others"
                        />
                    </RadioGroup>
                    {isOther && (
                        <TextField
                            label={'Enter the work place'}
                            onChange={(e: any) => {
                                setText(e.target.value);
                                onCallback({otherChefHub: e.target.value})
                            }}
                            value={text}
                        />
                    )}
                </FormControl>
            ) : ''}
        </Box>
    );
};

export default CurrentCitySelect;
