import { CreateResult, GetListResult, GetOneResult } from "react-admin";
import HttpClient from "../HttpClient";
import CityResponse from "../interfaces/City/CityResponse";
import CreateCity from "../interfaces/City/CreateCity";
import cleanFilters from "../utils/CleanFilters";
import ServiceResponse from "../interfaces/Service/ServiceResponse";
import {ReferenceModelNameEnums} from "../enums/ReferenceModelNameEnums";

export default class CityService {
    async fetchAllCities(limit: number, offset: number, filters: {}, sort: {}): Promise<GetListResult<CityResponse>> {
        filters = cleanFilters(filters, []);
        let data = await HttpClient.get('/centaurus/houses/cities', {
            params: {
                limit,
                offset,
                filters,
                sort
            }
        })
        return {
            data: (!!data && !!data?.data) ? data?.data?.data : [],
            total: (!!data && !!data?.data) ? data?.data?.count : 0
        };
    }

    async fetchOne(cityId: number): Promise<GetOneResult<CityResponse>> {
        const city = await HttpClient.get(`/centaurus/houses/cities/${cityId}`);
        return city;
    }

    async getMany(ids: Array<number>): Promise<GetListResult<CityResponse>> {
        const data = []

        for (const id of ids) {
            const res = await this.fetchOne(id)
            data.push(res.data)
        }

        return {
            data: data,
            total: data?.length
        }
    }

    async create(payload: CreateCity): Promise<CreateResult<CityResponse>> {
        const res = await HttpClient.post(`/centaurus/houses/city`, payload);
        return {
            data : res?.data
        }
    }

    async getAllServiceOfferings(payload: {
        referenceId: number;
        referenceModelName: ReferenceModelNameEnums;
    }): Promise<ServiceResponse[]> {
        const res = await HttpClient.get(`/houses/get-offering-by-slc`, {
            params: {
                ...payload,
            }
        });
        return res?.data;
    }

    async getAllServices(): Promise<ServiceResponse> {
        const res = await HttpClient.get(`/houses/get-service-offerings`);
        return res?.data;
    }

    async createServiceForSlc(payload: {
        referenceId: number;
        referenceModelName: ReferenceModelNameEnums;
        serviceId: number;
        isServing: boolean;
    }): Promise<ServiceResponse> {
        const res = await HttpClient.post(`/houses/create-slc-service-mapping`, payload);
        return res?.data;
    }

    async updateServiceForSlc(payload: {
        referenceId: number;
        referenceModelName: ReferenceModelNameEnums;
        serviceId: number;
        isServing: boolean;
    }): Promise<ServiceResponse> {
        const res = await HttpClient.patch(`/houses/create-slc-service-mapping/${payload?.serviceId}`, payload);
        return res?.data;
    }
}
